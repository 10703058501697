import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { BACKEND_IP } from '../../..';

const SuccessPage = ({ onClose }) => {
  const [paymentDetails, setPaymentDetails] = useState(null);
  const location = useLocation();

  useEffect(() => {
    // Parse the query parameters
    const queryParams = new URLSearchParams(location.search);
    const sessionId = queryParams.get('session_id');

    // Fetch payment details from the backend
    fetch(`${BACKEND_IP}/api/payment-details?session_id=${sessionId}`)
      .then(response => response.json())
      .then(data => setPaymentDetails(data))
      .catch(error => console.error('Error fetching payment details:', error));
  }, [location]);

  if (!paymentDetails) {
    return <div>Loading...</div>;
  }

  return (
    <div className="modalBackground">
      <div className="modalContainer">
        <button onClick={onClose}>Close</button>
        <h1>Payment Success</h1>
        <p>Thank you for your payment!</p>
        {/* Display payment and user details here using paymentDetails */}
      </div>
    </div>
  );
};
export default SuccessPage;
