import React, { Fragment } from 'react';
import { CheckIcon, EyeIcon, TrashIcon } from '@heroicons/react/20/solid';

function TasksByMonth({ todos, handleView, handleComplete, handleDeleteClick, hideCompletedTasks }) {
  // Convert todos object into a single array of tasks
  const allTodosArray = Object.values(todos).flat();

  // Filter tasks based on completion status
  const visibleTodos = hideCompletedTasks ? allTodosArray.filter(task => !task.is_completed) : allTodosArray;

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const groupTasksByMonth = (tasks) => {
    return tasks.reduce((acc, task) => {
      let monthYear = new Date(task.date).toLocaleString('default', { month: 'long', year: 'numeric' });
      monthYear = capitalizeFirstLetter(monthYear);
      if (!acc[monthYear]) {
        acc[monthYear] = [];
      }
      acc[monthYear].push(task);
      return acc;
    }, {});
  };

  const tasksGroupedByMonth = groupTasksByMonth(visibleTodos);

  return (
    <div className="rounded-lg overflow-hidden shadow">
      <table className="min-w-full">
        <thead className="bg-sky-800">
          {/* <tr>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider w-20"></th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider md:w-200 task-column">Tâche</th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Date limite</th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider"></th>
          </tr> */}
        </thead>
         <tbody className="bg-white">
          {Object.entries(tasksGroupedByMonth).map(([monthYear, tasks]) => (
            <Fragment key={monthYear}>
              <tr className="border-t bg-slate-200">
                <td colSpan={4} className="px-6 py-2 text-left text-md font-semibold text-sky-800">{monthYear}</td>
              </tr>
              {tasks.map(task => (
                <tr key={task.id} className={`border-t border-gray-200 ${task.is_completed ? 'bg-sky-800 bg-opacity-5' : ''}`}>
                   <td className="px-6 py-3 w-10">
                    <div className="flex items-center space-x-4">
                      <div
                        className={`rounded-lg w-5 h-5 border-2 ${task.is_completed ? 'border-sky-500 bg-sky-500' : 'border-gray-300'} flex items-center justify-center cursor-pointer`}
                        onClick={() => handleComplete(task.id, task.is_completed)}
                      >
                        {task.is_completed ? <CheckIcon className="h-3 w-3 text-white" /> : null}
                      </div>
                    </div>
                  </td>
                  <td className={`px-6 py-3 text-left font-medium text-sm text-sky-800 md:w-200 task-column ${task.is_completed ? 'line-through' : ''}`}>
                    {task.title}
                  </td>
                  <td className={`px-6 py-2 text-left font-medium text-sm text-sky-800 ${task.is_completed ? 'line-through' : ''}`}>
                    {task.date ? new Date(task.date).toLocaleDateString('fr-FR', { day: 'numeric', month: 'long', year: 'numeric' }) : ''}
                  </td>
                  <td className="px-6 py-3 w-20">
                    <div className="flex items-center gap-2">
                      <EyeIcon onClick={() => handleView(task)} className="h-5 w-5 text-blue-500 cursor-pointer" />
                      <TrashIcon onClick={() => handleDeleteClick(task)} className="h-5 w-5 text-red-500 cursor-pointer" />
                    </div>
                  </td>
                </tr>
              ))}
            </Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default TasksByMonth;
