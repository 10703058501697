import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { TrashIcon } from '@heroicons/react/20/solid';
import { BACKEND_IP } from '../..'; // Ensure this path is correct

const TablesList = () => {
  const [tables, setTables] = useState([]);

  useEffect(() => {
    const fetchTables = async () => {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${BACKEND_IP}/api/tables`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setTables(response.data);
    };

    fetchTables();
  }, []);

  const handleDeleteTable = async (tableId) => {
    const token = localStorage.getItem('token');
    await axios.delete(`${BACKEND_IP}/api/tables/${tableId}`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    // Refresh the tables list after deleting
    const updatedTables = tables.filter(table => table.id !== tableId);
    setTables(updatedTables);
  };

  return (
    <div>
      {tables.length > 0 ? (
        tables.map((table) => (
          <div key={table.id} className="flex items-center justify-between p-2">
            <p>{table.name}</p>
            <button onClick={() => handleDeleteTable(table.id)} className="flex items-center justify-center p-1 hover:bg-red-100 rounded">
              <TrashIcon className="h-5 w-5 text-red-500" />
            </button>
          </div>
        ))
      ) : (
        <p>No tables available</p>
      )}
    </div>
  );
};

export default TablesList;
