import React from 'react';

const Conseil = ({ title, text, imageUrl }) => {
  return (
    <div className="p-6 rounded-lg mt-4 border border-dashed border-slate-400 ">
      <div className="flex items-center space-x-4"> 
        <img src={imageUrl} alt="Profile" className="w-10 h-10 rounded-full border-white border" />
        <h2 className="text-lg font-regular font-dm-serif text-sky-900">{title}</h2>
      </div>
      <p className="mt-2 text-sm text-slate-500 italic">"{text}"</p>
    </div>
  );
};

export default Conseil;
