import { useState } from "react";
import { googleLogout } from "@react-oauth/google";
export function useAuth() {
  const [authenticated, setAuthenticated] = useState(
    !!localStorage.getItem("token")
  );

  const logout = (callback) => {
    localStorage.removeItem("token");
    // log out function to log the user out of google and set the profile array to null
    googleLogout();
    setAuthenticated(false);
    if (callback) callback();
  };

  return { authenticated, setAuthenticated, logout };
}
