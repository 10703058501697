import React, { useState, Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const DropdownFilter = ({ items, placeholder, selected, onChange, className }) => {
  return (
    <Menu as="div" className={`relative text-left ${className}`}>
      <div>
        <Menu.Button className="inline-flex w-full justify-between rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          <span>{selected || placeholder}</span>
          <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute z-10 mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {items.map((item) => (
              <Menu.Item key={item}>
                {({ active }) => (
                  <button
                    onClick={() => onChange(selected === item ? '' : item)}
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      selected === item ? 'font-semibold' : '',
                      'block w-full px-4 py-2 text-left text-sm'
                    )}
                  >
                    {item} {selected === item && <span className="text-green-500">&#10003;</span>}
                  </button>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

function GuestListFilters({ filters, setFilters, diets, relationships, marriageSides }) {
  const handleFilterChange = (field, value) => {
    setFilters({ ...filters, [field]: value });
  };

  return (
      <div className="flex flex-col w-full gap-3 mt-0">
          <div className="">
                      <label htmlFor="eventTitle" className="block text-sm font-medium text-sky-900 mb-1">Nom</label>
      <input
        type="text"
        placeholder="Nom ou prénom"
        value={filters.name}
        onChange={(e) => handleFilterChange('name', e.target.value)}
        className
        ="block w-full rounded-lg border-gray-300 py-2 text-sky-900 placeholder-gray-400 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm mt-1"
              />

        <div className="mb-4 mt-4">
                      <label htmlFor="eventTitle" className="block text-sm font-medium text-sky-900 mb-1">Régime</label>
              
      <DropdownFilter
        items={diets}
        placeholder="Tous"
        selected={filters.diet}
        onChange={(value) => handleFilterChange('diet', value)}
              />
              </div>
     <div className="mb-4 mt-4">
    <label htmlFor="eventTitle" className="block text-sm font-medium text-sky-900 mb-1">Relation</label>
      <DropdownFilter
        items={relationships}
        placeholder="Toutes"
        selected={filters.relationship}
        onChange={(value) => handleFilterChange('relationship', value)}
                  />
              </div>
             <div className="mt-4">
  <label htmlFor="side" className="block text-sm font-medium text-sky-900 mb-1">Côté</label>
  <DropdownFilter
    items={marriageSides}
    placeholder="Tous"
    selected={filters.side}
    onChange={(value) => handleFilterChange('side', value)}
  />
</div>
          </div>
          </div>
  );
}

export default GuestListFilters;
