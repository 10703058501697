import React, { useState } from 'react';
import axios from 'axios'; // Make sure you have axios installed or use fetch API
import { jwtDecode } from 'jwt-decode'; // Correct import syntax for named export
import { BACKEND_IP } from '../..'; // Ensure this path is correct for your project


const ResetPassword = () => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handlePasswordChange = async (e) => {
    e.preventDefault();

    if (!currentPassword || !newPassword || !confirmNewPassword) {
      setError('Please fill in all fields');
      return;
    }

    if (newPassword !== confirmNewPassword) {
      setError('New passwords do not match');
      return;
    }

    try {
      // Decode the token to get the user's ID
      const token = localStorage.getItem('token');
      const decodedToken = jwtDecode(token);
      const userId = decodedToken.id;

      // Make the API call to change the password
      await axios.post(`${BACKEND_IP}/api/user/change-password`, {
        userId,
        currentPassword,
        newPassword,
      });

      setSuccess('Password changed successfully');
      setError('');
    } catch (err) {
      setError(err.response?.data || 'An error occurred. Please try again.');
    }
  };

  return (
    
         <div className='bg-white p-6 mt-4 rounded-lg '>

      <div className='flex'>
        <div className='w-1/3'>
          <h4 className='text-lg font-semibold'>Modifier mot de passe</h4>
          <p>Changer votre mot de passe de MonWeddingPlanner.</p>

        </div>
         <div className='w-2/3'>
        <div className='mt-4'>
      <form onSubmit={handlePasswordChange} className='space-y-4 mt-4'>
        {error && <p className='text-red-500'>{error}</p>}
        {success && <p className='text-green-500'>{success}</p>}
        <div>
          <label htmlFor='currentPassword' className='block text-sm font-medium text-gray-700'>
            Mot de passe actuel
          </label>
          <input
            type='password'
            name='currentPassword'
            id='currentPassword'
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
            className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2'
            required
          />
        </div>
        <div>
          <label htmlFor='newPassword' className='block text-sm font-medium text-gray-700'>
            Nouveau mot de passe
          </label>
          <input
            type='password'
            name='newPassword'
            id='newPassword'
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2'
            required
          />
        </div>
        <div>
          <label htmlFor='confirmNewPassword' className='block text-sm font-medium text-gray-700'>
            Répétez le nouveau mot de passe
          </label>
          <input
            type='password'
            name='confirmNewPassword'
            id='confirmNewPassword'
            value={confirmNewPassword}
            onChange={(e) => setConfirmNewPassword(e.target.value)}
            className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2'
            required
          />
        </div>
        <button
          type='submit'
          className='mt-4 px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700'
        >
          Change Password
        </button>
      </form>
          </div>
        </div>
      </div>
      </div>
        
 
  );
};

export default ResetPassword;