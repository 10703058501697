// Layout.js
import React from 'react';
import Sidebar from './Sidebar';

// Layout.js
const Layout = ({ children }) => {
  return (
    <div className="flex flex-col lg:flex-row min-h-screen">
      <Sidebar />
      <div className="flex-1 bg-slate-100 p-1 pl-3 pt-0.5 content-section">
        {/* This will contain the page content */}
        {children}
      </div>
    </div>
  );
};

export default Layout;
