import React, { useState, useEffect } from 'react';
import axios from 'axios';
import GuestListTable from './GuestListTable';
import GuestStats from '../Home/GuestStats';
import { jwtDecode } from 'jwt-decode';
import useFetchGuests from '../../hooks/useFetchGuests';
import { BACKEND_IP } from '../..';
import GuestListFilters from './GuestListFilters';
import GuestListTable2 from './GuestListTable2';
import AddGuestModal from './AddGuestModal';
import { debounce } from 'lodash';
import Button2 from '../BaseAssets/Button2';
import {
  CalendarIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  EllipsisHorizontalIcon,
  MapPinIcon,
  UserPlusIcon,
  CalendarDaysIcon
} from '@heroicons/react/20/solid';
import Conseil from '../BaseAssets/Conseil';
import useFetchMarriageNames from '../../hooks/useFetchMarriageNames';


const GuestsComponent = () => {
  const [newGuests, setNewGuests] = useState([{ firstName: '', lastName: '', email: '', diet: '', relationship: '', side: '' }]);
  const [isFormOpen, setIsFormOpen] = useState(false);
    const [filters, setFilters] = useState({
    name: '', // Combined filter for first_name and last_name
    diet: '',
    relationship: '',
    side: '',
    });
  
  const marriageSides = useFetchMarriageNames();
    const [activeTab, setActiveTab] = useState('table1'); // 'table1' for GuestListTable, 'table2' for GuestListTable2





  const diets = ["Vegetarian", "🌱 Vegan", "Gluten-Free", "Halal", "Kosher", "No Preferences"];
  const relationships = ["Family", "Friends", "Work", "Other"];
  const [modalOpen, setModalOpen] = useState(false);
  

const openModal = () => setModalOpen(true);
const closeModal = () => setModalOpen(false);

  const [guests, fetchGuests] = useFetchGuests();

    const handleDeleteGuest = (index) => {
    setNewGuests(newGuests.filter((_, i) => i !== index));
  };

  const handleInputChange = (index, e) => {
    const updatedGuests = [...newGuests];
    updatedGuests[index][e.target.name] = e.target.value;
    setNewGuests(updatedGuests);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token');
    const decoded = jwtDecode(token);
    const userId = decoded.id;

    await Promise.all(newGuests.map(guest => 
      axios.post(`${BACKEND_IP}/api/guests`, { ...guest, userId }, {
        headers: { Authorization: `Bearer ${token}` }
      })
    ));
    fetchGuests();
    setNewGuests([{ firstName: '', lastName: '', email: '', diet: '', relationship: '', side: '' }]);
  };

  const addGuest = () => {
    setNewGuests([...newGuests, { firstName: '', lastName: '', email: '', diet: '', relationship: '', side: '' }]);
  };

  // Inside GuestsComponent

const getFilteredGuests = () => {
  return guests.filter(guest =>
    (guest.first_name?.toLowerCase().includes(filters.name.toLowerCase()) ||
     guest.last_name?.toLowerCase().includes(filters.name.toLowerCase())) &&
    guest.diet?.toLowerCase().includes(filters.diet.toLowerCase()) &&
    guest.relationship?.toLowerCase().includes(filters.relationship.toLowerCase()) &&
    guest.side?.toLowerCase().includes(filters.side.toLowerCase())
  );
};



  const filteredGuests = getFilteredGuests();



// Pass `filteredGuests` instead of `guests` to GuestListTable
<GuestListTable guests={filteredGuests} fetchGuests={fetchGuests} diets={diets} relationships={relationships} />


  return (
  
   <div className="flex flex-col md:flex-row">
    <div className='md:w-1/4'>

        <div className="pr-6">
        <Button2 onClick={openModal} label="Ajouter une invité(e)" icon={UserPlusIcon} color="wp" className=" font-bold py-2 px-4 rounded w-full mb-4"/>



           <div className="bg-white shadow-sm rounded-lg p-7 py-6 pb-9">
          <div className="">
                      <div className="flex items-center mb-4">
          <h4 className="text-md mb-2 font-bold leading-6 text-sky-800 font-dm">Filtres</h4>
        </div>

             <GuestListFilters
  filters={filters}
  setFilters={setFilters}
  diets={diets}
  relationships={relationships}
  marriageSides={marriageSides} // Pass the fetched marriage names
/>
              </div>
              </div>
          
              <Conseil 
        title="Le conseil de Caroline" 
        text="Vous pouvez ici noter tous les évènements liés à votre mariage : le jour J, les cérémonies, mais aussi les rendez-vous importants ou à ne pas manquer." 
        imageUrl="images/ppconseil.png"
      />
            
          </div>

        </div>
            
              <AddGuestModal
        isOpen={modalOpen}
        onClose={closeModal}
        newGuests={newGuests}
        handleInputChange={handleInputChange}
        handleSubmit={handleSubmit}
        addGuest={addGuest}
        diets={diets}
        relationships={relationships}
        marriageSides={marriageSides} // Pass the fetched marriage names as props
      />
      
      <div className="md:w-3/4">
        <div className="flex mb-4">
          <button
            className={`px-4 py-2 rounded-full mr-3 font-semibold text-sm transition duration-100 ease-in-out ${activeTab === 'table1' ? 'text-sky-800 font-semibold bg-sky-800 bg-opacity-20 tab-beige ' : 'text-gray-500 bg-sky-800 bg-opacity-5'}`}
            onClick={() => setActiveTab('table1')}
          >
            Général
          </button>
          <button
            className={`px-4 py-2 rounded-full mr-3 font-semibold text-sm transition duration-100 ease-in-out ${activeTab === 'table2' ? 'text-sky-800 font-semibold bg-sky-800 bg-opacity-20 tab-beige ' : 'text-gray-500 bg-sky-800 bg-opacity-5'}`}
            onClick={() => setActiveTab('table2')}
          >
            Repas
          </button>
        </div>
{/* Conditional rendering of table components based on the active tab */}
        {activeTab === 'table1' && <GuestListTable guests={filteredGuests} fetchGuests={fetchGuests} />}
        {activeTab === 'table2' && <GuestListTable2 guests={filteredGuests} fetchGuests={fetchGuests} />}    </div>
      
      </div>
);

};

export default GuestsComponent;
