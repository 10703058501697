import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { BACKEND_IP } from '..';

const useFetchGuests = () => {
  const [guests, setGuests] = useState([]);

  const fetchGuests = useCallback(async () => {
    const token = localStorage.getItem('token');
    if (token) {
      const response = await axios.get(`${BACKEND_IP}/api/guests/user`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setGuests(response.data);
    }
  }, []);

  useEffect(() => {
    fetchGuests();
  }, [fetchGuests]);

  return [guests, fetchGuests]; // Return both guests and fetchGuests function
};

export default useFetchGuests;
