import React, { useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import axios from 'axios';
import { BACKEND_IP } from '../..'; // Ensure this path is correct for your project
import Modal from '../BaseAssets/Modal';
import TextInput from '../BaseAssets/TextInput';
import WPButton from '../BaseAssets/WPButton';
import Dropdown from '../BaseAssets/Dropdown';



function AddEventModal({ isOpen, onClose, onSave }) {
  const [eventData, setEventData] = useState({
    title: '',
    event_date: '',
    time: '', // Added time state
    location: '', // Added location state
    description: '',
    event_type: '', // This holds the selected event type
  });


  const handleSelect = (value) => {
    setEventData({ ...eventData, event_type: value });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEventData({ ...eventData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token');


    try {
      await axios.post(
        `${BACKEND_IP}/calendar_event`,
        eventData,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      onSave(eventData); // Pass the eventData to the onSave function
      onClose(); // Close the modal
    } catch (error) {
      console.error('Error submitting event data:', error);
    }
  };

return (
    <Modal isOpen={isOpen} onClose={onClose} title="Ajouter un évènement" icon="/images/MWP.png">
      <form onSubmit={handleSubmit} className="space-y-1 mt-4">
        <div className="flex flex-col ">
        {/* First Column */}
        <div className='flex space-x-4'>
          <div className='w-1/2'>
            <TextInput
              label="Titre"
              name="title"
              value={eventData.title}
              onChange={handleChange}
              placeholder="Enter title"
            />
          </div>
           <div className='w-1/2'>
          {/* Second Column */}
            <TextInput
              label="Lieu"
              name="location"
              value={eventData.location}
              onChange={handleChange}
              placeholder="Enter location"
            />
            </div>
        </div>

        <div className='flex space-x-4'>
          <div className='w-1/3'>
                    <TextInput
              label="Date"
              name="event_date"
              type="date"
              value={eventData.event_date}
              onChange={handleChange}
          />
          </div>
          <div className='w-1/3'>
                    <TextInput
              label="Heure"
              name="time"
              type="time"
              value={eventData.time}
              onChange={handleChange}
            />
          </div>
          
          <div className='w-1/3'>
                    <label htmlFor="eventType" className="block mt-0 text-sm font-medium text-gray-700 mb-3">
            Type
          </label>
          <Dropdown
            selected={eventData.event_type}
            setSelected={handleSelect}
            options={[
              { value: 'Prestataires', label: 'Prestataires' },
              { value: 'Evenements', label: 'Evenements' },
              { value: 'A faire', label: 'A faire' },
            ]}
            placeholder="Choisir ..."
            />
            </div>
        </div>
        </div>

        {/* Remaining fields */}
        <div>

        </div>

        <div>
          <label htmlFor="description" className="block text-sm font-medium text-gray-700 mb-3">
            Description
          </label>
          <textarea
            name="description"
            id="description"
            value={eventData.description}
            onChange={handleChange}
            rows="3"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>
        <div className="mt-5 sm:mt-6">
          <WPButton
          type="submit"
          label="Ajouter"
            className="inline-flex mt-4 justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
          />
          
        </div>
      </form>
    </Modal>
  );
}

export default AddEventModal;
