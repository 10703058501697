import React from 'react';
import { ArrowRightIcon } from '@heroicons/react/outline'; // Example icon import

const colorSchemes = {
  blue: 'fond-bleu hover:bg-sky-700 border-sky-700',
  green: 'bg-green-600 hover:bg-green-700 border-green-700',
  red: 'bg-red-600 hover:bg-red-700 border-red-700',
  gray: 'bg-gray-400 hover:bg-gray-500 border-gray-500',
  wp: 'fond-bleu hover:bg-sky-700  text-white',
  light:'bg-slate-200 hover:bg-slate-300 border-slate-300'
};

const Button2 = ({ onClick, link, label, color = 'blue', className, type = 'button', icon }) => {
  const colorStyle = colorSchemes[color] || colorSchemes.blue;
  // Add border width and border style to the baseStyle
  const baseStyle = `flex justify-start items-center text-left font-semibold text-sm py-3 px-3 rounded-lg  ${colorStyle} ${className}`;

  const renderIcon = () => {
    if (!icon) return null;
    const IconComponent = icon;
    return <IconComponent className="w-5 h-5 mr-3" />;
  };

  if (link) {
    return (
      <a href={link} className={baseStyle}>
            {renderIcon()}
                    <span>{label}</span>

      </a>
    );
  }

  return (
    <button type={type} onClick={onClick} className={baseStyle}>
      {renderIcon()}
          <span>{label}</span>
     
    </button>
  );
};

export default Button2;
