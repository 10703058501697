import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Conseil from '../BaseAssets/Conseil';

// Make sure BACKEND_IP is correctly defined in your environment or configuration
import { BACKEND_IP } from '../..';

function PrestatairesList() {
  const [prestataires, setPrestataires] = useState([]);

  // This function constructs the URL for an image stored in the public/images directory
  function getImageForPrestataire(name) {
    // Replace spaces with underscores or any other character as per your naming convention
    const formattedName = name.replace(/\s+/g, '_');
    return `/images/${formattedName}.png`;
  }

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get(`${BACKEND_IP}/prestataires`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setPrestataires(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="space-y-6">
      <div className="flex">
        <div className='w-1/4'>
          <div className='px-4 -mt-4'>
            <Conseil 
              title="Le conseil de Caroline" 
              text="Vous pouvez ici noter tous les évènements liés à votre mariage : le jour J, les cérémonies, mais aussi les rendez-vous importants ou à ne pas manquer." 
              imageUrl="images/ppconseil.png"
            />
          </div>
        </div>
        <div className='w-3/4'>
          <div className='px-4'>
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
              {prestataires.map((prestataire) => (
                <button
                  key={prestataire.id}
                  type="button"
                  className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-6 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  <img src={getImageForPrestataire(prestataire.name)} alt={prestataire.name} className="mx-auto h-12 w-12" />
                  <span className="mt-2 block text-sm font-semibold text-gray-900">
                    {prestataire.name}
                  </span>
                  <span className="mt-1 block text-sm text-gray-500">
                    {prestataire.description}
                  </span>
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PrestatairesList;
