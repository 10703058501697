import React from 'react';
import { ChevronRightIcon } from '@heroicons/react/20/solid';

const EventListView = ({ events, onViewEvent }) => {
  const eventsByMonth = events
    .sort((a, b) => new Date(a.event_date) - new Date(b.event_date)) // Sort events by date
    .reduce((acc, event) => {
      const month = new Date(event.event_date).toLocaleString('default', { month: 'long', year: 'numeric' });
      const capitalizedMonth = month.charAt(0).toUpperCase() + month.slice(1);
      if (!acc[capitalizedMonth]) {
        acc[capitalizedMonth] = [];
      }
      acc[capitalizedMonth].push(event);
      return acc;
    }, {});

  // Function to format the time for display
  const formatTime = (time) => {
    return time ? time.slice(0, 5) : 'N/A';
  };
    
    
const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { weekday: 'long', day: 'numeric', month: 'long' };
    const formattedDate = date.toLocaleDateString('fr-FR', options);
    return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
};

return (
    <ul role="list" className="divide-y divide-gray-100 overflow-hidden bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl">
      {Object.entries(eventsByMonth)
        .sort((a, b) => new Date(a[0]) - new Date(b[0])) // Sort months
        .map(([month, events]) => (
        <React.Fragment key={month}>
          <li className="px-4 py-4 text-md font-semibold text-sky-700 bg-gray-50 sm:px-6">{month}</li>
          {events.map((event) => (
            <li key={event.id} className="relative flex justify-between gap-x-6 px-4 py-5 hover:bg-gray-50 sm:px-6">
              <div className="flex min-w-0 gap-x-4">
            <div className="flex-shrink-0">
              <span className="inline-block h-12 w-12 rounded-full overflow-hidden bg-gray-100 p-2">
                <img className="h-full w-full object-cover" src="/images/calendar-icon.png" alt="Calendar Icon" />
              </span>
            </div>
                <div className="min-w-0 flex-1">
                  <p className="text-md font-semibold leading-6 text-sky-900 cursor-pointer hover:underline" onClick={() => onViewEvent(event)}>
                    {event.title}
                  </p>
                  <p className="mt-1 text-md leading-5 text-gray-500">{event.location || 'N/A'}</p>
                </div>
              </div>
              <div className="flex flex-col shrink-0 items-end">
                <p className="mt-1 text-sm leading-5 font-medium text-slate-800">{formatDate(event.event_date)}</p>
                <p className="text-sm text-slate-700">{event.time ? event.time.slice(0, 5) : 'N/A'}</p>
              </div>
            </li>
          ))}
        </React.Fragment>
      ))}
    </ul>
  );
};

export default EventListView;