import React, { useState, Fragment } from 'react';
import axios from 'axios';
import { Dialog, Transition } from '@headlessui/react';
import { BACKEND_IP } from "../.."; // Adjust accordingly
import TextInput from "../BaseAssets/TextInput";
import WPButton from '../BaseAssets/WPButton';
import Dropdown from '../BaseAssets/Dropdown';

const EventsModal = ({ isOpen, onClose, onSave, event }) => {
  // Convert the event date to the 'YYYY-MM-DD' format for the date input
  const formattedEventDate = event?.event_date ? new Date(event.event_date).toISOString().split('T')[0] : '';

  const [title, setTitle] = useState(event?.title || '');
  const [location, setLocation] = useState(event?.location || '');
  const [time, setTime] = useState(event?.time?.slice(0, 5) || '00:00');
  const [description, setDescription] = useState(event?.description || '');
  const [eventType, setEventType] = useState(event?.event_type || '');
  const [eventDate, setEventDate] = useState(formattedEventDate);

  const handleSelect = (value) => {
    setEventType(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const updatedEvent = {
      ...event,
      title,
      location,
      time,
      description,
      event_type: eventType,
      event_date: eventDate,
    };

    try {
      const response = await axios.put(`${BACKEND_IP}/calendar_event/${event.id}`, updatedEvent, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });

      if (response.status === 200) {
        onSave(updatedEvent);
        onClose();
      } else {
        console.error('Failed to update event');
      }
    } catch (error) {
      console.error('Error updating event:', error);
    }
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" onClose={onClose}>
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-30" />
          </Transition.Child>

          <span className="inline-block h-screen align-middle" aria-hidden="true">&#8203;</span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
              <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">Modifier l'évènement</Dialog.Title>
              <form onSubmit={handleSubmit} className="mt-2 space-y-6">
                <TextInput label="Titre" name="title" value={title} onChange={(e) => setTitle(e.target.value)} />

                  <TextInput label="Lieu" name="location" value={location} onChange={(e) => setLocation(e.target.value)} className="flex-1" />

                <div className="flex space-x-4">
                  <TextInput label="Date" name="event_date" type="date" value={eventDate} onChange={(e) => setEventDate(e.target.value)} className="flex-1" />
                  <TextInput label="Heure" name="time" type="time" value={time} onChange={(e) => setTime(e.target.value)} className="flex-1" />
                  <div className="flex-1">
                    <label htmlFor="event_type" className="block text-sm font-medium text-gray-700 mb-1">Type</label>
                    <Dropdown
                      name="event_type"
                      selected={eventType}
                      onChange={handleSelect}
                      options={[
                        { value: 'Prestataires', label: 'Prestataires' },
                        { value: 'Evenements', label: 'Evenements' },
                        { value: 'A faire', label: 'A faire' },
                      ]}
                    />
                  </div>
                </div>

                <div>
                  <label htmlFor="description" className="block text-sm font-medium text-gray-700 mb-1">Description</label>
                  <textarea
                    name="description"
                    id="description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    rows="4"
                    className="block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500"
                  />
                </div>

                <div className="flex justify-end">
                  <WPButton type="submit" label="Enregistrer" />
                </div>
              </form>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default EventsModal;
