import { useEffect } from 'react';

const useChatBubble = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://www.chatbase.co/embed.min.js';
    script.defer = true;
    document.body.appendChild(script);

    window.embeddedChatbotConfig = {
      chatbotId: 'aiqb-02SZtFAJGvnbKTZX',
      domain: 'www.chatbase.co',
    };
    
    return () => {
  document.body.removeChild(script);
    };
    
    }, []);
};

export default useChatBubble;
