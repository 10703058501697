import React from 'react';
import Tabs from '../BaseAssets/Tabs';
import Utilisateurs from './Utilisateurs';
import ResetPassword from './ResetPassword';
import HelpCenter from './HelpCenter';

const tabsData = [
  { name: 'Utilisateurs', content: <Utilisateurs /> },
    { name: 'Mot de passe', content: <div><ResetPassword /></div> },
    { name: "Besoin d'aide ?", content: <div><HelpCenter /></div> },

];

function SettingsComponent() {
  return (
    <div>
      <Tabs tabs={tabsData} />
    </div>
  );
}

export default SettingsComponent;
