import React from 'react';
import Layout from '../components/BaseAssets/Layout';
import PageTitle from '../components/BaseAssets/PageTitle';
import GuestsComponent from '../components/Invites/GuestsComponent';

const Invites = () => {
  return (
    <Layout>
      <div className="p-4">
      <PageTitle 
        title="Les invités" 
        subtitle="La liste des invités du mariage." 
      />
    <GuestsComponent />
      </div>
    </Layout>
  );
}

export default Invites;
