import React, { useState } from 'react';
import { TrashIcon, EyeIcon } from '@heroicons/react/24/solid'; // Importing heroicons
import DeleteConfirmationModal from '../BaseAssets/DeleteConfirmationModal';
import BudgetStats from './BudgetStats';
import EditBudgetItemModal from './EditBudgetItemModal';

const BudgetTable = ({ budgetItems, onDelete, updateBudgetItem, setBudgetItems }) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [itemToEdit, setItemToEdit] = useState(null);
  
  
  const handleDeleteClick = (item) => {
    setItemToDelete(item);
    setIsDeleteModalOpen(true);
  };

  const handleConfirmDelete = () => {
    onDelete(itemToDelete.id);
    setIsDeleteModalOpen(false);
  };

  const handleViewClick = (item) => {
    setItemToEdit(item);
    setIsEditModalOpen(true);
  };



  return (
    <div className="flex flex-col w-full">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Nom</th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Type</th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Prix</th>
                  <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"></th>
                </tr>
              </thead>
              <tbody>
                {budgetItems.map((item, itemIdx) => (
                  <tr key={item.id} className={itemIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-semibold text-sky-900">{item.name}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-sky-900">{item.type}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-sky-900">
                      {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(item.price)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 flex justify-end items-center">
                      <button onClick={() => handleViewClick(item)} className="mr-3">
                        <EyeIcon className="h-4 w-4 text-sky-800 hover:text-sky-600" />
                      </button>
                      <button onClick={() => handleDeleteClick(item)} className="text-slate-300 hover:text-red-500">
                        <TrashIcon className="h-4 w-4" />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <DeleteConfirmationModal isOpen={isDeleteModalOpen} onClose={() => setIsDeleteModalOpen(false)} onConfirm={handleConfirmDelete} itemName={itemToDelete?.name} />
<EditBudgetItemModal
  isOpen={isEditModalOpen}
  onClose={() => setIsEditModalOpen(false)}
  item={itemToEdit}
  updateBudgetItem={updateBudgetItem} // Passed down to EditBudgetItemModal
/>


      {/* Edit Budget Item Modal - Make sure to create this component */}
    </div>
  );
};

export default BudgetTable;
