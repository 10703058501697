import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faBriefcase, faEuroSign, faClipboardList } from '@fortawesome/free-solid-svg-icons';
import useFetchTasks from '../../hooks/useFetchTasks';
import axios from 'axios';
import { BACKEND_IP } from '../..';

const FeatureCard = ({ title, count, icon, totalExpenses }) => (
  <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
    <div className="flex items-center mb-3">
      <FontAwesomeIcon icon={icon} className="mr-3 w-4 h-4 text-sky-800" />
      <dt className="truncate text-md font-medium text-sky-800 ">{title}</dt>
    </div>
    <dd className="mt-1 text-2xl font-bold tracking-tight text-sky-700">{count} </dd>
{/* {title === 'Budget' && totalExpenses && (
  <dd className="mt-1 text-sm font-semibold tracking-semibold text-sky-600">Dépenses: {totalExpenses}</dd>
)} */}


  </div>
);


const RecapList = ({ guests }) => {
  const [tasks] = useFetchTasks();
  // Assuming you have these totals calculated

    const [totalBudget, setTotalBudget] = useState(0);
  const [totalExpenses, setTotalExpenses] = useState(0);

  const totalTasks = tasks?.length ?? 'Loading...';
  const completedTasks = tasks?.filter(task => task.is_completed)?.length ?? 'Loading...';
  const tasksToDo = `${completedTasks}/${totalTasks}`;

  const guestCount = guests?.length ?? 'Loading...';

  useEffect(() => {
const fetchTotals = async () => {
  const token = localStorage.getItem('token');
  if (token) {
    try {
      const budgetResponse = await axios.get(`${BACKEND_IP}/api/budget/total`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      console.log('Budget Response:', budgetResponse.data); // Log to inspect
      setTotalBudget(parseFloat(budgetResponse.data.total)); // Ensure it's a number

      const expensesResponse = await axios.get(`${BACKEND_IP}/api/expenses/total`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      console.log('Expenses Response:', expensesResponse.data); // Log to inspect
      setTotalExpenses(parseFloat(expensesResponse.data.total)); // Ensure it's a number
    } catch (error) {
      console.error('Error fetching totals:', error); // Log the error object
    }
  }
};



  fetchTotals();
}, []);


const features = [
  { title: 'Invités', count: guestCount, icon: faUsers },
  { title: 'Prestataires', count: '...', icon: faBriefcase },
  {
    title: 'Budget',
    count: new Intl.NumberFormat('en-US', { style: 'currency', currency: 'EUR' }).format(totalBudget),
    totalExpenses: new Intl.NumberFormat('en-US', { style: 'currency', currency: 'EUR' }).format(totalExpenses),
    icon: faEuroSign
  },
  { title: 'Tâches', count: tasksToDo, icon: faClipboardList },
];

  return (
    <div>
      <dl className="grid grid-cols-1 gap-5 sm:grid-cols-2">
        {features.map((feature, index) => (
          <FeatureCard key={index} {...feature} />
        ))}
      </dl>
    </div>
  );
};


export default RecapList;
