import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
// export const BACKEND_IP = "https://my-wedding-planner-backend.vercel.app"
export const BACKEND_IP = "http://localhost:3001";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
//updated

