import React from 'react';

// Define color schemes
const colorSchemes = {
  blue: 'fond-bleu hover:bg-sky-700',
  green: 'bg-green-600 hover:bg-green-700',
  red: 'bg-red-600 hover:bg-red-700',
  gray: 'bg-gray-400 hover:bg-gray-500',
};

const Button = ({ onClick, link, label, color = 'blue', className, type = 'button' }) => {
  // Select the color scheme based on the color prop, defaulting to blue if not specified or if an invalid value is provided
  const colorStyle = colorSchemes[color] || colorSchemes.blue;
  const baseStyle = `text-white font-semibold py-2 px-4 rounded-lg ${colorStyle} ${className}`;

  if (link) {
    return (
      <a href={link} className={baseStyle}>
        {label}
      </a>
    );
  }

  return (
    <button type={type} onClick={onClick} className={baseStyle}>
      {label}
    </button>
  );
};

export default Button;
