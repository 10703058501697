import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../useAuth';
import { BACKEND_IP } from '..';

const Signup = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [agreeMarketing, setAgreeMarketing] = useState(false);
  // const [repeatPassword, setRepeatPassword] = useState('');
  const navigate = useNavigate();
  const { setAuthenticated } = useAuth();

  localStorage.setItem('isNewUser', 'true');


  const handleSubmit = async (e) => {
  e.preventDefault();
  if (!agreeTerms) {
    alert("Please agree to the terms and conditions.");
    return;
  }

  // if (password !== repeatPassword) {
  //   alert("Passwords do not match.");
  //   return;
  // }

  try {
  const response = await axios.post(`${BACKEND_IP}/register`, {
    email, password, first_name: firstName, last_name: lastName
  });
  localStorage.setItem('token', response.data.token);
  localStorage.setItem('isNewUser', 'true'); // Set flag for new user
  setAuthenticated(true);
  navigate('/');
} catch (error) {
  console.error(error);
  // Display a user-friendly error message based on the error response
  if (error.response && error.response.status === 400) {
    alert("User already exists. Please log in or use a different email.");
  } else {
    alert("An error occurred. Please try again later.");
  }
}
};


 return (
  <div className="flex min-h-screen flex-col bg-slate-100 py-4 sm:px-6 lg:px-8 justify-center">
    <div className='flex flex-col justify-center items-center'>

      

      <div className='flex flex-row content-center shadow rounded-xl mx-44  align-middle justify-center w-3/4'>
        <div className='w-1/2 relative rounded-l-xl overflow-hidden'>
          {/* Overlay with semi-transparent background */}
          <div className='absolute inset-0 bg-black bg-opacity-0 z-10'></div>

          {/* Background image */}
          <div className='absolute inset-0 bg-cover bg-center z-0'
               style={{ backgroundImage: "url('/images/signup1.jpg')" }}
          ></div>

          {/* Content */}
         

      </div>
         <div className='w-1/2 bg-white px-12 py-16 pt-8 rounded-r-xl sm:px-16'>
           
           <div className="sm:mx-auto sm:w-full sm:max-w-[480px]">
              <div className="flex justify-center mb-4">
        <img
          src="/images/MWP.png"
          alt="Your Company"
          style={{
            width: "230px",
            marginTop: "0px",
          }}
        />
      </div>
              <div>
                                  <a
                  href="www.google.com"
                  className="flex w-full items-center justify-center gap-3 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:ring-transparent"
                >
                  <svg className="h-5 w-5" aria-hidden="true" viewBox="0 0 24 24">
                    <path
                      d="M12.0003 4.75C13.7703 4.75 15.3553 5.36002 16.6053 6.54998L20.0303 3.125C17.9502 1.19 15.2353 0 12.0003 0C7.31028 0 3.25527 2.69 1.28027 6.60998L5.27028 9.70498C6.21525 6.86002 8.87028 4.75 12.0003 4.75Z"
                      fill="#EA4335"
                    />
                    <path
                      d="M23.49 12.275C23.49 11.49 23.415 10.73 23.3 10H12V14.51H18.47C18.18 15.99 17.34 17.25 16.08 18.1L19.945 21.1C22.2 19.01 23.49 15.92 23.49 12.275Z"
                      fill="#4285F4"
                    />
                    <path
                      d="M5.26498 14.2949C5.02498 13.5699 4.88501 12.7999 4.88501 11.9999C4.88501 11.1999 5.01998 10.4299 5.26498 9.7049L1.275 6.60986C0.46 8.22986 0 10.0599 0 11.9999C0 13.9399 0.46 15.7699 1.28 17.3899L5.26498 14.2949Z"
                      fill="#FBBC05"
                    />
                    <path
                      d="M12.0004 24.0001C15.2404 24.0001 17.9654 22.935 19.9454 21.095L16.0804 18.095C15.0054 18.82 13.6204 19.245 12.0004 19.245C8.8704 19.245 6.21537 17.135 5.2654 14.29L1.27539 17.385C3.25539 21.31 7.3104 24.0001 12.0004 24.0001Z"
                      fill="#34A853"
                    />
                  </svg>
                  <span className="text-sm font-semibold leading-6">Créer mon compte via Google</span>
                </a>
              </div>
              {/* Other social sign up options can be added here */}
      </div>
        <div className="mt-6 sm:mx-auto sm:w-full sm:max-w-[480px]">
  <div className="">
    <form className="space-y-4" onSubmit={handleSubmit}>
      <div className="flex gap-4">
        <div className="flex-1">
          <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900">
            Prénom
          </label>
          <input
            id="first-name"
                       name="first-name"
                       placeholder='Votre prénom'
            type="text"
            required
            className="mt-1 block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
        </div>
        <div className="flex-1">
          <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-gray-900">
            Nom
          </label>
          <input
            id="last-name"
                       name="last-name"
                       placeholder='Votre nom'
            type="text"
            required
            className="mt-1 block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
        </div>
      </div>

            <div>
              <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                Adresse e-mail
              </label>
              <div className="mt-1">
                <input
                  id="email"
                  name="email"
                       type="email"
                       placeholder='Votre adresse e-mail'
                  autoComplete="email"
                  required
                  className="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>

            <div>
            <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
              Mot de passe
            </label>
            <input
              id="password"
              name="password"
                     type="password"
                     placeholder='Minimum 8 caractères'
              autoComplete="current-password"
              required
              className="mt-1 block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
                      </div>
                      
                      

            <div className="flex items-start">
              <input
                id="agree-terms"
                name="agree-terms"
                type="checkbox"
                className="h-4 w-4 mt-1 text-sky-600 border-gray-300 rounded focus:ring-sky-500"
                checked={agreeTerms}
                onChange={(e) => setAgreeTerms(e.target.checked)}
              />
              <label htmlFor="agree-terms" className="ml-2 block text-sm text-gray-600">
                J'accepte les <a href="#" className="text-sky-600 hover:text-sky-500">Conditions de confidentialité</a> de MonWeddingPlanner.
              </label>
            </div>

            {/* <div className="flex items-start">
              <input
                id="agree-marketing"
                name="agree-marketing"
                type="checkbox"
                className="h-4 w-4 mt-1 text-sky-600 border-gray-300 rounded focus:ring-sky-500"
                checked={agreeMarketing}
                onChange={(e) => setAgreeMarketing(e.target.checked)}
              />
              <label htmlFor="agree-marketing" className="ml-2 block text-sm text-gray-600">
                Je veux recevoir les bons plans mariage de MonWeddingPlanner (1 mail par mois max ;)
              </label>
            </div> */}

            <div>
              <button
                type="submit"
                className="flex w-full mt-6 justify-center rounded-md bg-sky-700 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-sky-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600"
              >
                Créer mon compte
              </button>
            </div>
          </form>

  
          <p className="mt-4 text-center text-sm text-gray-600">
            Vous avez déjà un compte?{' '}
            <a href="/login" className="font-medium text-sky-600 hover:text-sky-500">
              Me connecter
            </a>
          </p>
        </div>
      </div>
        </div>
      </div>
      </div>



    </div>
  );
};

export default Signup;
