import React from 'react';

const TaskStats = ({ totalTasks, completedTasks }) => {
  return (
    <div className="bg-white p-4 border rounded-lg flex justify-between items-center">
      <div>
        <h4 className="text-md font-semibold text-gray-700">Tasks</h4>
      </div>
      <div>
        <p className="text-lg font-bold">{`${completedTasks}/${totalTasks}`}</p>
      </div>
    </div>
  );
};

export default TaskStats;
