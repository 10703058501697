// Home.js
import React from 'react';
import Layout from '../components/BaseAssets/Layout';
import PageTitle from '../components/BaseAssets/PageTitle';
import PrestatairesList from '../components/Prestataires/PrestatairesList';

function Prestataires() {

  return (
    <Layout>
      <div className="p-4">
        <PageTitle 
          title="Les prestataires" 
          subtitle="Toutes les actualités de mon mariage ♥" 
        />
      </div>
      <PrestatairesList />
    </Layout>
  );
}

export default Prestataires;
