import React, { useState, useEffect } from 'react';
import Layout from '../components/BaseAssets/Layout';
import PageTitle from '../components/BaseAssets/PageTitle';
import GuestStats from '../components/Home/GuestStats';
import { useLocation } from 'react-router-dom';
import useFetchGuests from '../hooks/useFetchGuests';
import useFetchTasks from '../hooks/useFetchTasks';
import OnboardingModal from '../components/Onboarding/OnboardingModal';
import OnboardingCompletion from '../components/Onboarding/OnboardingCompletion';
import MariageWelcome from '../components/Home/MariageWelcome';
import TaskStats from '../components/BaseAssets/TaskStats';
import UpcomingTasksList from '../components/Home/UpcomingTasksList';
import TaskDetailsModal from '../components/Todo/TaskDetailsModal';
import CheckoutComponent from '../components/Onboarding/Checkout/CheckoutComponent'; // Import your CheckoutComponent
import SuccessPage from '../components/Onboarding/Checkout/SuccessPage';
import Modal from '../components/BaseAssets/Modal';

import axios from 'axios';
import { BACKEND_IP } from '..';
import MariageRecap from '../components/Home/MariageRecap';

function Home() {
  const [guests] = useFetchGuests();
  const [tasks] = useFetchTasks();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const location = useLocation();

  const [userId, setUserId] = useState(null);

  const [isTaskModalOpen, setIsTaskModalOpen] = useState(false);
  const [currentTask, setCurrentTask] = useState(null);

  const handleViewTaskDetails = (task) => {
    setCurrentTask(task);
    setIsTaskModalOpen(true);
  };

  const handleCloseTaskModal = () => {
    setIsTaskModalOpen(false);
    setCurrentTask(null);
  };

   useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const sessionId = queryParams.get('session_id');
    if (sessionId) {
      setShowSuccessModal(true);
    }
  }, [location]);

  const handleCloseSuccessModal = () => {
    setShowSuccessModal(false);
    // Optionally, you can also remove the session_id from the URL here
  };

  useEffect(() => {
    const isNewUser = localStorage.getItem('isNewUser') === 'true';
    const token = localStorage.getItem('token');
    if (token && isNewUser) {
      setIsModalOpen(true); // Show the checkout modal if it's a new user
      localStorage.removeItem('isNewUser'); // Ensure it's only shown once
    }

    const fetchUser = async () => {
      try {
        if (token) {
          const config = { headers: { Authorization: `Bearer ${token}` } };
          const response = await axios.get(`${BACKEND_IP}/user`, config);
          setUserId(response.data.id);
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUser();
  }, []);

  const totalTasks = tasks.length;
  const completedTasks = tasks.filter(task => task.is_completed).length;

  return (
    <Layout>
      <div className="p-4">
        <PageTitle title="Tableau de bord" subtitle="L'organisation de notre Jour J dans un seul endroit ♥" />
        <div className='flex flex-col md:flex-row gap-8 mb-4'>
          <div className='md:w-3/5'><MariageWelcome /></div>
          <div className='md:w-2/5'><MariageRecap /><UpcomingTasksList tasks={tasks} onViewDetails={handleViewTaskDetails} />
            {isTaskModalOpen && (
              <TaskDetailsModal task={currentTask} onClose={handleCloseTaskModal} />
            )}
          </div>
        </div>
        <div className='flex gap-2'></div>
      </div>
      {userId && isModalOpen && 
        <OnboardingModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
          {/* Replace the OnboardingForm with the CheckoutComponent */}
          <CheckoutComponent />
        </OnboardingModal>
      }
{/* 
      <button onClick={() => setIsModalOpen(true)} className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700 transition duration-300">
  Trigger Checkout Modal
      </button> */}
      <Modal isOpen={showSuccessModal} onClose={handleCloseSuccessModal} title="Payment Success">
        <SuccessPage /> {/* You might need to adjust SuccessPage to not include its own modal/dialog structure */}
      </Modal>
    </Layout>
  );
}

export default Home;
