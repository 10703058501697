import React, { useState } from 'react';
import Dropdown from '../BaseAssets/Dropdown';
import { Dialog } from '@headlessui/react';
import axios from 'axios'; // Ensure axios is imported if making HTTP requests
import { PlusCircleIcon, PlusIcon } from '@heroicons/react/20/solid';
import { BACKEND_IP } from '../..';
import WPButton from '../BaseAssets/WPButton';
import Button2 from '../BaseAssets/Button2';


const taskTypes = [
  { value: 'Organisation', label: 'Organisation' },
  { value: 'Prestataires', label: 'Prestataires' },
  { value: 'Invités', label: 'Invités' },
  { value: 'Rendez-vous', label: 'Rendez-vous' },

];

function AddTask({ onTaskAdded }) {
  const [isOpen, setIsOpen] = useState(false);
  const [newTask, setNewTask] = useState('');
  const [newTaskType, setNewTaskType] = useState(taskTypes[0]?.value);
  const [newTaskDescription, setNewTaskDescription] = useState('');
  const [newTaskDate, setNewTaskDate] = useState('');

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

const handleAddTask = async (e) => {
    e.preventDefault();
    // Task addition logic here, e.g., POST request to backend
    try {
      const response = await axios.post(`${BACKEND_IP}/todo/custom`, {
        title: newTask,
        type: newTaskType,
        custom_description: newTaskDescription,
        date: newTaskDate,
      }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });

      // Assuming the response includes the added task, emit it to the parent component
      onTaskAdded(response.data);

      // Reset form and close modal
      setNewTask('');
      setNewTaskType(taskTypes[0]?.value);
      setNewTaskDescription('');
      setNewTaskDate('');
      closeModal();
    } catch (error) {
      console.error('Error adding task:', error);
    }
  };

    return (
        <>
                <div className="">
            <Button2 label="Ajouter une tâche" onClick={openModal} icon={PlusIcon} className=" text-white font-bold py-2 px-4 rounded w-full mb-4" />
</div>

            <Dialog open={isOpen} onClose={closeModal} className="fixed inset-0 z-10 overflow-y-auto">
                <div className="flex items-center justify-center min-h-screen">
                    <Dialog.Overlay className="fixed inset-0 bg-sky-900 opacity-30" />

                    <div className="relative bg-white rounded-lg shadow-xl mx-auto max-w-sm w-full p-6">
                        <Dialog.Title className="text-lg font-semibold text-sky-900">Ajouter une tâche</Dialog.Title>
                            <form onSubmit={handleAddTask} className="mt-4">
                            <div className="flex flex-col gap-4">
                                <input
                                    type="text"
                                    value={newTask}
                                    onChange={(e) => setNewTask(e.target.value)}
                                    placeholder="Titre"
                                    className="block w-full rounded-md border-gray-300 p-2"
                                />
                                <Dropdown
                                    selected={newTaskType}
                                    setSelected={setNewTaskType}
                                    options={taskTypes}
                                    placeholder="Type de tâche"
                                />
                                <textarea
                                    value={newTaskDescription}
                                    onChange={(e) => setNewTaskDescription(e.target.value)}
                                    placeholder="Description"
                                    className="block w-full rounded-md border-gray-300 p-2"
                                />
                                <input
                                    type="date"
                                    value={newTaskDate}
                                    onChange={(e) => setNewTaskDate(e.target.value)}
                                    className="block w-full rounded-md border-gray-300 p-2"
                                />
                                <button
                                    type="submit"
                                    className="block w-full rounded-md bg-sky-700 p-2 text-white"
                                >
                                    Ajouter
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </Dialog>
        </>
    );
}

export default AddTask;
