import { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useAuth } from "../../useAuth";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useLocation } from "react-router-dom";
import i18n from "../../i18n";
import { useTranslation } from "react-i18next";

import {
  Bars3Icon,
  CalendarIcon,
  ChartPieIcon,
  DocumentDuplicateIcon,
  FolderIcon,
  HomeIcon,
  UsersIcon,
  CalendarDaysIcon,
  CheckBadgeIcon,
  CurrencyEuroIcon,
  BriefcaseIcon,
  Squares2X2Icon,
  HeartIcon,
  ComputerDesktopIcon,
  Cog6ToothIcon,
} from "@heroicons/react/24/outline";
import { BACKEND_IP } from "../..";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Sidebar() {
  const navigate = useNavigate();
  const { logout, authenticated } = useAuth();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [userData, setUserData] = useState({ first_name: "", last_name: "" });
  const location = useLocation();
  const [showButtons, setShowButtons] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { t, i18n } = useTranslation();

  // Function to change the language
  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  const navigation = [
    {
      name: "Tableau de bord",
      href: "/",
      icon: HomeIcon,
      submenu: "Principal",
      current: true,
    },
    {
      name: "Nos informations",
      href: "/informations",
      icon: HeartIcon,
      submenu: "Mariage",
      current: false,
    },
    {
      name: "Ma to-do",
      href: "/todo",
      icon: CheckBadgeIcon,
      submenu: "Principal",
      current: false,
    },
    {
      name: "Mes invités",
      href: "/invites",
      icon: UsersIcon,
      submenu: "Invites",
      current: false,
    },
    {
      name: "Plan des tables",
      href: "/plan-tables",
      icon: Squares2X2Icon,
      submenu: "Invites",
      current: false,
    },
    {
      name: "Le budget",
      href: "/budget",
      icon: CurrencyEuroIcon,
      submenu: "Organisation",
      current: false,
    },
    {
      name: "Mes prestataires",
      href: "/prestataires",
      icon: BriefcaseIcon,
      submenu: "Organisation",
      current: false,
    },
    {
      name: "Devis & Factures",
      href: "/devis-factures",
      icon: FolderIcon,
      submenu: "Organisation",
      current: false,
    },
    {
      name: "Calendrier",
      href: "/calendrier",
      icon: CalendarDaysIcon,
      submenu: "Principal",
      current: false,
    },
    {
      name: "Paramètres",
      href: "/parametres",
      icon: Cog6ToothIcon,
      submenu: "Mariage",
      current: false,
    },
  ];

  const submenuLabels = {
    Principal: t("main"),
    Invites: t("guests"),
    Organisation: t("organization"),
    Mariage: t("Our wedding"),
  };

  useEffect(() => {
    const fetchUserData = async () => {
      const token = localStorage.getItem("token"); // get the stored token
      if (token && authenticated) {
        try {
          const response = await axios.get(`${BACKEND_IP}/user`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          setUserData(response.data);
        } catch (error) {
          console.error("Error fetching user data", error);
        }
      }
    };

    fetchUserData();
  }, [authenticated]); // Dependency array to re-run the effect when authentication state changes

  const handleLogout = async () => {
    setIsLoading(true); // Start loading before the logout process

    const logoutPromise = new Promise((resolve) => {
      // Your logout logic here
      logout(() => {
        navigate("/login");
        resolve(); // Resolve the promise once logout and navigation are done
      });
    });

    const timeoutPromise = new Promise((resolve) => setTimeout(resolve, 2000)); // 2-second delay

    // Wait for both the logout process and the timeout to complete
    await Promise.all([logoutPromise, timeoutPromise]);

    setIsLoading(false); // Stop loading after the logout process and the timeout
  };

  const fullName = userData
    ? `${userData.first_name} ${userData.last_name}`
    : "User";

  const toggleButtons = () => {
    setShowButtons(!showButtons);
  };

  return (
    <>
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-50 lg:hidden"
            onClose={setSidebarOpen}
          >
            {/* Mobile Sidebar */}
            <div className="fixed inset-0 flex">
              <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-2">
                  <div className="flex h-16 shrink-0 items-center">
                    <img
                      className="h-8 w-auto"
                      src="/images/MWP.png"
                      alt="Your Company"
                    />
                  </div>
                  <nav className="flex flex-1 flex-col">
                    {Object.entries(submenuLabels).map(
                      ([submenuKey, submenuTitle]) => (
                        <div key={submenuKey}>
                          <div className="text-xs font-semibold leading-6 text-gray-400">
                            {submenuTitle}
                          </div>
                          <ul className="-mx-2 mt-2 space-y-1">
                            {navigation
                              .filter((item) => item.submenu === submenuKey)
                              .map((item) => (
                                <li key={item.name}>
                                  <a
                                    href={item.href}
                                    className={classNames(
                                      item.current
                                        ? "bg-gray-50 text-sky-600"
                                        : "text-gray-700 hover:text-sky-600 hover:bg-gray-50",
                                      "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                    )}
                                  >
                                    <item.icon
                                      className={classNames(
                                        item.current
                                          ? "text-sky-600"
                                          : "text-gray-400 group-hover:text-sky-600",
                                        "h-6 w-6 shrink-0"
                                      )}
                                      aria-hidden="true"
                                    />
                                    {item.name}
                                  </a>
                                </li>
                              ))}
                          </ul>
                        </div>
                      )
                    )}
                  </nav>

                  {/* Conditionally render the buttons */}
                    {showButtons && (
                      <>
                        <button
                          onClick={() => navigate("/profile")}
                          className="mt-2 w-full rounded bg-blue-500 py-2 text-sm text-white"
                        >
                          My Profile
                        </button>
                        <button
                          onClick={handleLogout}
                          className="mt-2 w-full rounded bg-red-500 py-2 text-sm text-white"
                        >
                          Logout
                        </button>
                      </>
                  )}
                  
                  <div className="absolute bottom-0 left-0 w-full px-6">
                    {/* Profile section with onClick handler */}
                    <div
                      className="flex items-center gap-x-4 py-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-50 bg-white border-t border-gray-200 cursor-pointer"
                      onClick={toggleButtons}
                    >
                      <img
                        className="h-8 w-8 rounded-full bg-gray-50"
                        src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                        alt=""
                      />
                      <span>{fullName}</span>
                    </div>

                    
                  </div>
                </div>
              </Dialog.Panel>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:block lg:flex-shrink-0 lg:w-72 lg:h-screen ">
          <div className="fixed top-0 lg:w-72 h-full overflow-y-auto shadow-md bg-white px-6">
            <div className="flex h-16 shrink-0 items-center">
              <img
                className="mt-5 w-52  "
                src="/images/MWP.png"
                alt="Your Company"
              />
            </div>
            <nav className="flex flex-1 flex-col">
              {Object.entries(submenuLabels).map(
                ([submenuKey, submenuTitle]) => (
                  <div key={submenuKey}>
                    <div className="text-xs font-semibold leading-6 text-slate-400 mt-3">
                      {submenuTitle}
                    </div>
                    <ul className="-mx-2 mt-2 space-y-1">
                      {navigation
                        .filter((item) => item.submenu === submenuKey)
                        .map((item) => (
                          <li key={item.name}>
                            <a
                              href={item.href}
                              className={classNames(
                                location.pathname === item.href
                                  ? "bg-slate-100 text-sky-700 "
                                  : "text-slate-700 hover:text-sky-700 hover:bg-slate-100 ",
                                "group flex gap-x-3 rounded-md p-1 text-sm leading-6 font-medium"
                              )}
                            >
                              <item.icon
                                className={classNames(
                                  location.pathname === item.href
                                    ? "text-sky-600"
                                    : "text-gray-400 group-hover:text-sky-600",
                                  "h-5 w-5 mt-0.5 shrink-0"
                                )}
                                aria-hidden="true"
                              />
                              {item.name}
                            </a>
                          </li>
                        ))}
                    </ul>
                  </div>
                )
              )}
            </nav>
            <div className="language-switcher"></div>
             {/* Logout Button */}
              {showButtons && (
                <div className="relative items-center flex flex-col">
                  <div className="w-[90%]">

                  <button
                    onClick={() => navigate("/profile")}
                    className="mt-2 w-full rounded bg-blue-500 py-2 text-sm text-white"
                    >
                    My Profile
                  </button>
                  <button
                    onClick={handleLogout}
                    className="w-full px-8 mt-2 rounded bg-red-500 py-2 text-sm text-white"
                    >
                    Logout
                  </button>
                </div>
                    </div>
              )}
            <div className="fixed bottom-0 left-0 lg:block lg:flex-shrink-0 lg:w-72">
              {/* Profile Section */}
              <div
                className="flex items-center px-4 gap-x-4 py-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-50 bg-white border-gray-200 cursor-pointer"
                onClick={toggleButtons}
              >
                <img
                  className="h-8 w-8 rounded-full bg-gray-50"
                  src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                  alt=""
                />
                <span>{fullName}</span>
              </div>

             
            </div>
          </div>
        </div>

        <div className="sticky top-0 z-40 flex items-center gap-x-6 bg-white px-4 py-4 shadow-sm sm:px-6 lg:hidden">
          <button
            type="button"
            className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
          <div className="flex-1 text-lg font-semibold leading-6 text-slate-700">
            Mon Wedding Planner
          </div>
          <a href="www.google.fr">
            <span className="sr-only">Your profile</span>
            <img
              className="h-8 w-8 rounded-full bg-gray-50"
              src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
              alt=""
            />
          </a>
        </div>
      </div>
    </>
  );
}
