import React from 'react';
import OnboardingCompletion from '../Onboarding/OnboardingCompletion';
import RecapList from './RecapList';
import useFetchGuests from '../../hooks/useFetchGuests'; // Ensure this path is correct

export default function Welcome() {
  const [guests] = useFetchGuests(); // Fetch the guests using your custom hook

  if (!guests) {
    return <div>Loading guests...</div>; // Handle loading state
  }

  return (
    <div className="">
      <RecapList guests={guests} />
      {/* <OnboardingCompletion /> if needed */}
    </div>
  );
}
