import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";
import Home from "./pages/Home";
import Signup from "./pages/SignUp";
import Login from "./pages/LogIn";
import ProtectedRoute from "./ProtectedRoute";
import Invites from "./pages/Invites";
import Budget from "./pages/Budget";
import Todo from "./pages/Todo";
import Calendrier from "./pages/Calendrier";
import PlanTables from "./pages/PlanTables";
import Prestataires from "./pages/Prestataires";
import DevisFactures from "./pages/DevisFactures";
import Informations from "./pages/Informations";
import Parametres from "./pages/Parametres";
import SuccessPage from "./components/Onboarding/Checkout/SuccessPage";
import "./custom-fonts.css";
function App() {
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_ID}>
      <Router>
        <Routes>
          {/* Define a Route that uses ProtectedRoute as its element */}
          <Route element={<ProtectedRoute />}>
            {/* Nest protected components as child routes of ProtectedRoute */}
            <Route path="/" element={<Home />} />
            <Route path="/invites" element={<Invites />} />
            <Route path="/budget" element={<Budget />} />
            <Route path="/calendrier" element={<Calendrier />} />
            <Route path="/todo" element={<Todo />} />
            <Route path="/prestataires" element={<Prestataires />} />
            <Route path="/plan-tables" element={<PlanTables />} />
            <Route path="/informations" element={<Informations />} />
            <Route path="/devis-factures" element={<DevisFactures />} />
            <Route path="/return" element={<SuccessPage />} />
            <Route path="/parametres" element={<Parametres />} />
          </Route>
          {/* Unprotected routes */}
          <Route path="/signup" element={<Signup />} />
          <Route path="/login" element={<Login />} />
        </Routes>
      </Router>
    </GoogleOAuthProvider>
    
  );
}

export default App;
