import { useState, useEffect } from 'react';
import axios from 'axios';
import { BACKEND_IP } from '..';

const useFetchTasks = () => {
  const [tasks, setTasks] = useState([]);

  useEffect(() => {
    const fetchTasks = async () => {
      try {
        const response = await axios.get(`${BACKEND_IP}/todo`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}` // Adjust as needed for your auth token
          }
        });
        setTasks(response.data);
      } catch (error) {
        console.error('Error fetching tasks:', error);
      }
    };

    fetchTasks();
  }, []);

  return [tasks];
};

export default useFetchTasks;
