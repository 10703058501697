import React, { useState, useEffect } from 'react';
import Layout from '../components/BaseAssets/Layout';
import PageTitle from '../components/BaseAssets/PageTitle';
import BudgetTable from '../components/Budget/BudgetTable';
import BudgetFormModal from '../components/Budget/BudgetFormModal'; // Import the BudgetFormModal component
import axios from 'axios';
import ExpenseFormModal from '../components/Budget/ExpenseFormModal';
import ExpensesTable from '../components/Budget/ExpensesTable';
import { BACKEND_IP } from '..';
import BudgetStats from '../components/Budget/BudgetStats';
import ExpensesStats from '../components/Budget/ExpensesStats';
import Button2 from '../components/BaseAssets/Button2';
import {
  CalendarIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  EllipsisHorizontalIcon,
  MapPinIcon,
  UserPlusIcon,
  CalendarDaysIcon,
  PlusCircleIcon,
} from '@heroicons/react/20/solid';
import Conseil from '../components/BaseAssets/Conseil';

function Budget() {
  const [budgetItems, setBudgetItems] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility
  const [isExpenseModalOpen, setIsExpenseModalOpen] = useState(false);
  const [activeTab, setActiveTab] = useState('budget'); // 'budget' or 'expenses'
  const [expenses, setExpenses] = useState([]); // State for expenses
  const totalBudget = budgetItems.reduce((acc, item) => acc + parseFloat(item.price || 0), 0);
  const totalExpenses = expenses.reduce((acc, expense) => acc + parseFloat(expense.amount || 0), 0);

  





  useEffect(() => {
    const fetchBudgetItems = async () => {
      try {
        const response = await axios.get(`${BACKEND_IP}/api/budget`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        });
        setBudgetItems(response.data);
      } catch (error) {
        console.error('Error fetching budget items:', error.response ? error.response.data : error);
      }
    };

    fetchBudgetItems();
  }, []);

useEffect(() => {
  const fetchExpenses = async () => {
    try {
      const response = await axios.get(`${BACKEND_IP}/api/expenses`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      setExpenses(response.data); // Update the state with fetched expenses
    } catch (error) {
      console.error('Error fetching expenses:', error.response ? error.response.data : error);
    }
  };

  fetchExpenses(); // Call the function to fetch expenses
}, []); // Empty dependency array means this effect runs once on component mount


  const addBudgetItem = async (newItem) => {
    try {
      const response = await axios.post(`${BACKEND_IP}/api/budget`, newItem, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      setBudgetItems(prevItems => [...prevItems, response.data]);
    } catch (error) {
      console.error('Error adding budget item:', error.response ? error.response.data : error);
    }
  };

  const deleteBudgetItem = async (itemId) => {
  try {
    await axios.delete(`${BACKEND_IP}/api/budget/${itemId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });

    // Remove the deleted item from the state
    setBudgetItems(currentItems => currentItems.filter(item => item.id !== itemId));
  } catch (error) {
    console.error('Error deleting budget item:', error.response ? error.response.data : error.message);
  }
  };

  const updateBudgetItem = async (updatedItem) => {
  try {
    const response = await axios.put(`${BACKEND_IP}/api/budget/${updatedItem.id}`, updatedItem, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });

    if (response.status === 200) {
      // Replace the item in the local state with the updated item from the backend
      const newBudgetItems = budgetItems.map(item => {
        if (item.id === updatedItem.id) {
          return { ...item, ...response.data };
        }
        return item;
      });

      setBudgetItems(newBudgetItems);

      // Optionally, close the modal or show a success message
    } else {
      console.error('Failed to update the budget item.');
      // Optionally, handle failure (e.g., show an error message)
    }
  } catch (error) {
    console.error('Error updating the budget item:', error);
    // Optionally, handle error (e.g., show an error message)
  }
};

  
 const deleteExpenseItem = async (itemId) => {
  try {
    const response = await axios.delete(`${BACKEND_IP}/api/expenses/${itemId}`, {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    });

    if (response.status === 200) {
      // Update local state to remove the deleted item
      setExpenses(currentExpenses => currentExpenses.filter(expense => expense.id !== itemId));
    } else {
      console.error('Failed to delete the expense item.');
    }
  } catch (error) {
    console.error('Error deleting expense item:', error.response ? error.response.data : error);
  }
};

  
  const addExpenseItem = async (newExpense) => {
  try {
    const response = await axios.post(`${BACKEND_IP}/api/expenses`, newExpense, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
    // Assuming you have a state for expenses similar to budgetItems
    // Update it here with the new expense
    // setExpenses(prevExpenses => [...prevExpenses, response.data]);
  } catch (error) {
    console.error('Error adding expense item:', error.response ? error.response.data : error);
  }
};

const updateExpenseItem = async (updatedItem) => {
  try {
    const response = await axios.put(`${BACKEND_IP}/api/expenses/${updatedItem.id}`, updatedItem, {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
    });

    if (response.status === 200) {
      const newExpenses = expenses.map(item => item.id === updatedItem.id ? { ...item, ...response.data } : item);
      setExpenses(newExpenses);
    } else {
      console.error('Failed to update the expense item.');
    }
  } catch (error) {
    console.error('Error updating the expense item:', error);
  }
};
  



  return (
    <Layout>
      <div className="p-4">
        <PageTitle title="Le budget" subtitle="Pour suivre toutes les dépenses."/>
      
           <div className="flex flex-col md:flex-row ">
    <div className='md:w-1/4'>

            <div className="pr-6">
              
  

      <Button2
        onClick={() => setIsModalOpen(true)}
                icon={PlusCircleIcon} color="wp"
                className=" font-bold py-2 px-4 rounded w-full mb-2" 
                label="Ajouter un budget"
              />
              
                    <Button2
  onClick={() => setIsExpenseModalOpen(true)}
  icon={PlusCircleIcon}
  color="light"
  className="font-bold py-2 px-4 rounded w-full mb-4 button-beige"
  label="Ajouter une dépense"
/>
       
      {/* Pass a boolean value to isOpen */}
      <BudgetFormModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        addBudgetItem={addBudgetItem}
              />
              
              <ExpenseFormModal
  isOpen={isExpenseModalOpen}
  onClose={() => setIsExpenseModalOpen(false)}
  addExpenseItem={addExpenseItem}
/>
           
            <Conseil 
        title="Le conseil de Caroline" 
        text="Vous pouvez ici noter tous les évènements liés à votre mariage : le jour J, les cérémonies, mais aussi les rendez-vous importants ou à ne pas manquer." 
        imageUrl="images/ppconseil.png"
              />
               </div>
            </div>
          <div className='md:w-3/4'>

            
            
            <div className="flex mb-4">
  <button
    onClick={() => setActiveTab('budget')}
className={`px-4 py-2 rounded-full mr-3 font-semibold text-sm transition duration-100 ease-in-out ${activeTab === 'budget' ? 'text-sky-800 font-semibold bg-sky-800 bg-opacity-20 tab-beige' : 'text-gray-500 bg-sky-800 bg-opacity-5'}`}  >
    Budget
  </button>
  <button
    onClick={() => setActiveTab('expenses')}
className={`px-4 py-2 rounded-full mr-3 font-semibold text-sm transition duration-100 ease-in-out ${activeTab === 'expenses' ? 'text-sky-800 font-semibold bg-sky-800 bg-opacity-20 tab-beige' : 'text-gray-500 bg-sky-800 bg-opacity-5'}`}  >
    Dépenses
  </button>
</div>
            <div className='flex gap-4'>
              
               <div className=" w-1/3 mb-4">
        <BudgetStats totalBudget={totalBudget} />
              </div>
              
                        <div className=" w-1/3 mb-4">

        <ExpensesStats totalExpenses={totalExpenses} />
            </div>
            
                 
            </div>
            
            <div className='w-full'>
              <div>
</div>
  {activeTab === 'budget' && <BudgetTable
  budgetItems={budgetItems}
  onDelete={deleteBudgetItem}
  updateBudgetItem={updateBudgetItem}
  setBudgetItems={setBudgetItems} // Pass setBudgetItems as a prop
/>
}
  {activeTab === 'expenses' && <ExpensesTable
  expenses={expenses}
  onDelete={deleteExpenseItem}
  onEditSave={updateExpenseItem} // Make sure this function is defined in this component
/>

}
</div>
          </div>
          </div>
          
         </div>
    </Layout>
  );
}

export default Budget;
