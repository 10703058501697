import React from 'react';
import Layout from '../components/BaseAssets/Layout';
import PageTitle from '../components/BaseAssets/PageTitle';
import Todo from '../components/Todo/TodoComponent'; // Make sure the import path is correct

function TodoPage() {
  return (
    <Layout>
      <div className="p-4">
        <PageTitle title="La todo du mariage" subtitle="Pour gérer notre mariage le plus simplement." />
        <Todo />
      </div>
    </Layout>
  );
}

export default TodoPage;
