import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWallet } from '@fortawesome/free-solid-svg-icons'; // Assuming you have FontAwesome set up

const BudgetStats = ({ totalBudget }) => {
  // Formatting the totalBudget as Euro currency
  const formattedTotalBudget = new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: 'EUR',
  }).format(totalBudget);

  return (
    <div className="bg-white p-4 border rounded-lg flex justify-between items-center">
      <div className="flex items-center">
        <FontAwesomeIcon icon={faWallet} className="text-sky-900 mr-3 h-4 w-4" /> {/* Wallet icon for budget */}
        <h4 className="text-md font-semibold text-sky-900">Budget</h4>
      </div>
      <div>
        <p className="text-lg font-bold text-sky-800">{formattedTotalBudget}</p> {/* Display formatted budget */}
      </div>
    </div>
  );
};

export default BudgetStats;
