import React, { useState, Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import {
  CheckIcon,
  ChevronDownIcon,
  AdjustmentsHorizontalIcon,
} from "@heroicons/react/20/solid";

function EventsFilter({
  selectedMonthYear,
  setSelectedMonthYear,
  setTitleFilter,
  typeFilters,
  setTypeFilters,
}) {
  const [monthYearOptions, setMonthYearOptions] = useState(
    generateMonthYearOptions()
  );

  function generateMonthYearOptions() {
    const options = [];
    const currentDate = new Date();
    currentDate.setMonth(currentDate.getMonth() - 3); // Start 3 months in the past

    for (let i = 0; i < 22; i++) {
      // 3 past months + current month + 18 future months = 22
      options.push({
        value: `${currentDate.getFullYear()}-${String(
          currentDate.getMonth() + 1
        ).padStart(2, "0")}`,
        label: `${new Date(
          currentDate.getFullYear(),
          currentDate.getMonth()
        ).toLocaleString("default", {
          month: "long",
        })} ${currentDate.getFullYear()}`,
      });
      currentDate.setMonth(currentDate.getMonth() + 1);
    }

    return options;
  }

  const handleTypeFilterChange = (e) => {
    const { value, checked } = e.target;
    setTypeFilters(
      checked
        ? [...typeFilters, value]
        : typeFilters.filter((type) => type !== value)
    );
  };

  return (
    <div className="bg-white shadow-sm rounded-lg p-7 py-6 pb-9">
      <div className="mb-4">
        <div className="flex items-center mb-4">
          <h4 className="text-md mb-2 font-bold leading-6 text-sky-800 font-dm">
            Filtres
          </h4>
        </div>
        <label
          htmlFor="eventTitle"
          className="block text-sm font-medium text-sky-900"
        >
          Titre
        </label>
        <input
          type="text"
          name="eventTitle"
          id="eventTitle"
          onChange={(e) => setTitleFilter(e.target.value)}
          className="block w-full rounded-lg border-gray-300 py-2 text-sky-900 placeholder-gray-400 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm mt-1"
          placeholder="Titre de l'évènement"
        />
      </div>

      <div className="mb-4">
        <label
          htmlFor="eventTitle"
          className="block text-sm font-medium text-sky-900 mb-1"
        >
          Date
        </label>

        <MonthYearDropdown
          options={monthYearOptions}
          selectedMonthYear={selectedMonthYear}
          setSelectedMonthYear={setSelectedMonthYear}
        />
      </div>

      <fieldset>
        <legend className="text-sm font-medium text-sky-900">Type</legend>
        <div className="mt-2 space-y-2">
          {["Prestataires", "Evenements", "A faire", "Social"].map((type) => (
            <div key={type} className="flex items-center">
              <input
                id={`type-${type}`}
                name="typeFilters"
                type="checkbox"
                value={type}
                checked={typeFilters.includes(type)}
                onChange={handleTypeFilterChange}
                className="h-4 w-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
              />
              <label
                htmlFor={`type-${type}`}
                className="ml-3 block text-sm text-sky-800"
              >
                {type}
              </label>
            </div>
          ))}
        </div>
      </fieldset>
    </div>
  );
}

function MonthYearDropdown({
  options,
  selectedMonthYear,
  setSelectedMonthYear,
}) {
  // Find the label for the selected month/year or default to "Toutes les dates"
  const selectedLabel = selectedMonthYear
    ? options.find((option) => option.value === selectedMonthYear)?.label
    : "Toutes les dates";

  return (
    <Menu as="div" className="relative inline-block text-left w-full">
      <div>
        <Menu.Button className="inline-flex justify-between rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 w-full">
          {selectedLabel}
          <ChevronDownIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute z-10 mt-2 w-full origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none max-h-60 overflow-y-auto">
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <button
                  type="button"
                  className={`${
                    active ? "bg-gray-100 text-gray-900" : "text-gray-700"
                  } group flex justify-between items-center w-full px-4 py-2 text-sm`}
                  onClick={() => setSelectedMonthYear("")}
                >
                  Toutes les dates
                </button>
              )}
            </Menu.Item>
            {options.map((option) => (
              <Menu.Item key={option.value}>
                {({ active }) => (
                  <button
                    type="button"
                    className={`${
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700"
                    } group flex justify-between items-center w-full px-4 py-2 text-sm`}
                    onClick={() => setSelectedMonthYear(option.value)}
                  >
                    {option.label}
                  </button>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

export default EventsFilter;
