// Import React and useState from 'react' for managing component state
import React, { useState } from 'react';
// Import your layout and UI components
import Layout from '../components/BaseAssets/Layout';
import PageTitle from '../components/BaseAssets/PageTitle';
import Modal from '../components/BaseAssets/Modal'; // Import the Modal component
import Button2 from '../components/BaseAssets/Button2'; // Import the Button component
import AddTableForm from '../components/Tables/AddTableForm';
import AssignGuestToTableForm from '../components/Tables/AssignGuestToTableForm';
import GuestsByTableList from '../components/Tables/GuestsByTableList';
import TablesList from '../components/Tables/TablesList';
import {
  UserPlusIcon,
  SquaresPlusIcon
} from '@heroicons/react/20/solid';

function PlanTables() {
  // State to control the visibility of the AddTableForm modal
  const [isAddTableModalOpen, setIsAddTableModalOpen] = useState(false);
  const [isManageTablesModalOpen, setIsManageTablesModalOpen] = useState(false);


  // Function to open the modal
  const handleOpenAddTableModal = () => {
    setIsAddTableModalOpen(true);
  };

  // Function to close the modal
  const handleCloseAddTableModal = () => {
    setIsAddTableModalOpen(false);
  };

  const handleOpenManageTablesModal = () => {
  setIsManageTablesModalOpen(true);
};

const handleCloseManageTablesModal = () => {
  setIsManageTablesModalOpen(false);
};


  return (
    <Layout>
      <div className="p-4">
        <PageTitle 
          title="Le Plan de tables" 
          subtitle="Toutes les actualités de mon mariage ♥" 
        />

        <div className='flex flex-col md:flex-row '>
          <div className='md:w-1/4 pr-6'>
            {/* Button that opens the AddTableForm modal */}
            <Button2 
              label="Ajouter une table" 
              onClick={handleOpenAddTableModal}
              icon={SquaresPlusIcon}
              color="wp"
              className=" font-bold py-2 px-4 rounded w-full mb-4"
            />

            {/* Modal containing the AddTableForm */}
            <Modal 
              isOpen={isAddTableModalOpen} 
              onClose={handleCloseAddTableModal} 
              title="Ajouter une table"
              
            >
              <AddTableForm /* Pass necessary props like mariageId here */ />
            </Modal>

            <Button2 
  label="Manage Tables" 
  onClick={handleOpenManageTablesModal}
  icon={SquaresPlusIcon}
  color="wp"
  className="font-bold py-2 px-4 rounded w-full mb-4"
/>

<Modal 
  isOpen={isManageTablesModalOpen} 
  onClose={handleCloseManageTablesModal} 
  title="Manage Tables"
>
  <TablesList onDelete={handleCloseManageTablesModal} /* You may pass additional props if needed */ />
</Modal>

            <div className='p-6 bg-white rounded-lg shadow'>
            <AssignGuestToTableForm />
            </div>
          </div>
          <div className='md:w-3/4'>
            <GuestsByTableList />
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default PlanTables;
