// Home.js
import React from 'react';
import Layout from '../components/BaseAssets/Layout';
import PageTitle from '../components/BaseAssets/PageTitle';
import InformationsComponent from '../components/Informations/InformationsComponent';

function Informations() {

  return (
    <Layout>
      <div className="p-4">
        <PageTitle 
          title="Les infos de notre mariage" 
          subtitle="Toutes les actualités de mon mariage ♥" 
        />
      </div>
      <InformationsComponent />
    </Layout>
  );
}

export default Informations;
