import React from 'react';

const TextInput = ({ label, name, type = 'text', placeholder, value, onChange, additionalProps }) => {
  return (
    <div className="mb-2">
      <label htmlFor={name} className="block text-sm font-medium leading-6 text-sky-900">
        {label}
      </label>
      <div className="mt-2">
        <input
          type={type}
          name={name}
          id={name}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          className="block w-full rounded-md border-0 py-1.5 text-sky-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          {...additionalProps}
        />
      </div>
    </div>
  );
};

export default TextInput;
