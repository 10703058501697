import React, { useState, useEffect, Fragment } from 'react';
import axios from 'axios';
import { PencilIcon, TrashIcon, ArrowDownIcon } from '@heroicons/react/20/solid';
import { BACKEND_IP } from '../..'; // Ensure this path is correct
import EditGuestModal from '../Invites/EditGuestModal';
import TablesList from './TablesList';

const GuestListTable = () => {
  const [guestsGroupedByTable, setGuestsGroupedByTable] = useState({});
  const [selectedTable, setSelectedTable] = useState(null);
  const [selectedGuest, setSelectedGuest] = useState(null);
  const [loading, setLoading] = useState(true);

  // Define fetchGuestsByTable outside useEffect
  const fetchGuestsByTable = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${BACKEND_IP}/api/guests/by-table`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setGuestsGroupedByTable(response.data);
      if (Object.keys(response.data).length > 0) {
        setSelectedTable(Object.keys(response.data)[0]);
      }
    } catch (error) {
      console.error('Error fetching guests by table:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchGuestsByTable();
  }, []);


  const handleUnlink = async (guestId) => {
    try {
      const token = localStorage.getItem('token');
      await axios.patch(`${BACKEND_IP}/api/guests/unlink`, { guest_id: guestId }, { headers: { Authorization: `Bearer ${token}` } });
      // Call fetchGuestsByTable to refresh the list after unlinking
      fetchGuestsByTable();
    } catch (error) {
      console.error('Error unlinking guest:', error);
    }
  };

  

  if (loading) {
    return <p>Loading guests...</p>;
  }



  const handleEdit = (guest) => {
    setSelectedGuest(guest);
  };

 const handleDelete = async (id) => {
  try {
    const token = localStorage.getItem('token');
    await axios.delete(`${BACKEND_IP}/api/guests/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    // Update guestsGroupedByTable by removing the deleted guest
    const updatedGuestsByTable = { ...guestsGroupedByTable };
    Object.keys(updatedGuestsByTable).forEach(tableName => {
      updatedGuestsByTable[tableName] = updatedGuestsByTable[tableName].filter(guest => guest.id !== id);
    });
    setGuestsGroupedByTable(updatedGuestsByTable);
  } catch (error) {
    console.error(error.message);
  }
};

  if (loading) {
    return <p>Loading guests...</p>;
  }

  

 const renderGuestsForTable = (tableName) => {
  const guests = guestsGroupedByTable[tableName];
  if (guests && guests.length > 0) {
    return (
      <table className="min-w-full divide-y divide-gray-200 shadow rounded-lg mt-4">
        <thead className="bg-white rounded-lg">
          <tr >
            <th className="px-6 py-3 text-left text-xs font-semibold text-gray-500">Nom</th>
            <th className="px-6 py-3 text-left text-xs font-semibold text-gray-500">Prénom</th>
            <th className="px-6 py-3 text-left text-xs font-semibold text-gray-500">Actions</th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {guests.map((guest) => (
            <tr key={guest.id}>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{guest.last_name}</td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{guest.first_name}</td>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                <button onClick={() => handleEdit(guest)} className="text-indigo-600 hover:text-indigo-900 mr-3">
                  <PencilIcon className="h-5 w-5" aria-hidden="true" />
                </button>
                <button onClick={() => handleUnlink(guest.id)} className="text-yellow-500 hover:text-yellow-700 mr-3">
                  <ArrowDownIcon className="h-5 w-5" aria-hidden="true" />
                </button>
                <button onClick={() => handleDelete(guest.id)} className="text-red-600 hover:text-red-900">
                  <TrashIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  } else {
    return <p>No guests</p>;
  }
};

  if (loading) {
    return <p>Loading guests...</p>;
  }

  return (
    <div>
      <div className="tabs">
        {Object.keys(guestsGroupedByTable).map((tableName) => (
          <button
            key={tableName}
          className={`tab px-4 py-2 rounded-full mr-3 font-semibold text-sm transition duration-100 ease-in-out ${selectedTable === tableName ? 'text-sky-800 font-semibold bg-sky-800 bg-opacity-20 tab-beige ' : 'text-gray-500 bg-sky-800 bg-opacity-5'}`}
            onClick={() => setSelectedTable(tableName)}
          >
            {tableName}
          </button>
        ))}
      </div>
      <div className="tab-content">
        {selectedTable && (
          <Fragment>
            {/* <h3 className="text-xl font-semibold my-4">{selectedTable}</h3> */}
            {renderGuestsForTable(selectedTable)}
          </Fragment>
        )}
      </div>

      {selectedGuest && (
        <EditGuestModal
          guest={selectedGuest}
          onClose={() => setSelectedGuest(null)}
          // Pass a method to update the guests list after editing
        />
      )}
    </div>
  );
};

export default GuestListTable;