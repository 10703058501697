import React, { Fragment, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';

const Modal = ({ isOpen, onClose, title, icon, children }) => {
  useEffect(() => {
    let timeoutId;
    if (!isOpen) {
      timeoutId = setTimeout(() => {
        onClose();
      }, 300); // Match this with your leave transition duration
    }
    return () => clearTimeout(timeoutId); // Clear the timeout if the component unmounts or isOpen changes before the timeout completes
  }, [isOpen, onClose]);

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" onClose={onClose}>
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-slate-900 bg-opacity-70 transition-opacity" />
          </Transition.Child>

          <span className="inline-block h-screen align-middle" aria-hidden="true">&#8203;</span>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-300"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-md overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-lg overflow-y-auto pb-6" >
              {/* Title with Icon */}
              <div className="flex items-center pl-6 pt-5 ">
                {/* {icon && <img src={icon} alt="Icon" className="mr-2 w-6 h-6" />} */}
                <h2 className="text-xl font-bold font-dm text-sky-800 ">{title}</h2>
              </div>
              {/* Modal Content with Scrollable Area */}
              <div className="max-w-2xl overflow-y-auto p-6 py-0">{children}</div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default Modal;
