import React, { useState, useEffect, Fragment } from 'react';
import axios from 'axios';
import {
  CalendarIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  EllipsisHorizontalIcon,
  MapPinIcon,
  CalendarDaysIcon
} from '@heroicons/react/20/solid';
import { Menu, Transition } from '@headlessui/react';
import AddEventModal from './AddEventModal'; // Make sure this import path is correct
import { BACKEND_IP } from '../..';
import WPButton from '../BaseAssets/WPButton';
import EventsFilter from './EventsFilter';
import Conseil from '../BaseAssets/Conseil';
import Button2 from '../BaseAssets/Button2';
import CalendarComponent2 from './CalendarComponent2';




function CalendarComponent() {
  const [events, setEvents] = useState([]);
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedMonthYear, setSelectedMonthYear] = useState('');
const [titleFilter, setTitleFilter] = useState('');
const [typeFilters, setTypeFilters] = useState([]);


  useEffect(() => {
    const fetchEvents = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get(`${BACKEND_IP}/calendar_events`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setEvents(response.data);
      } catch (error) {
        console.error('Error fetching events:', error);
      }
    };

    fetchEvents();
  }, [currentMonth, currentYear, isModalOpen]);

    const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const date = new Date(dateString);
    return date.toLocaleDateString('fr-FR', options);
  };

const formatTime = (timeString) => {
  if (!timeString) return '';
  const [hours, minutes] = timeString.split(':');
  return `${hours}h${minutes}`;
};



  const handleAddEventClick = () => {
    setIsModalOpen(true);
  };

  const handleSaveEvent = async (newEvent) => {
    setIsModalOpen(false);
    // After saving the event, fetch events again to update the list
    const fetchEvents = async () => {
      const token = localStorage.getItem('token');
const response = await axios.get(`${BACKEND_IP}/calendar_events`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setEvents(response.data);
    };

    fetchEvents();
  };

    const filteredEvents = events.filter((event) => {
  const eventDate = new Date(event.event_date);
  const eventMonthYear = `${eventDate.getFullYear()}-${String(eventDate.getMonth() + 1).padStart(2, '0')}`;
  
  // Filter by month and year
  const monthYearMatch = selectedMonthYear === '' || eventMonthYear === selectedMonthYear;
  
  // Filter by title
  const titleMatch = event.title.toLowerCase().includes(titleFilter.toLowerCase());
  
  // Filter by event type
  const typeMatch = typeFilters.length === 0 || typeFilters.includes(event.event_type);

  return monthYearMatch && titleMatch && typeMatch;
});

  

return (
<div className="flex flex-col md:flex-row">
    {/* Column 1: Add Event Button */}
    <div className="md:w-1/4 sm:w-full pr-6">
      <Button2 onClick={handleAddEventClick} label="Ajouter un évènement" icon={CalendarDaysIcon} color="wp" className=" font-bold py-2 px-4 rounded w-full mb-4"/>
      <AddEventModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} onSave={handleSaveEvent} />
      <EventsFilter
        selectedMonthYear={selectedMonthYear}
        setSelectedMonthYear={setSelectedMonthYear}
        setTitleFilter={setTitleFilter}
        typeFilters={typeFilters}
        setTypeFilters={setTypeFilters}
      />
    
      <Conseil 
        title="Le conseil de Caroline" 
        text="Vous pouvez ici noter tous les évènements liés à votre mariage : le jour J, les cérémonies, mais aussi les rendez-vous importants ou à ne pas manquer." 
        imageUrl="images/ppconseil.png"
      />
</div>

    {/* Column 2: Events List */}
    <div className="md:w-3/4 sm:w-full  pb-12">

      <CalendarComponent2 
      typeFilters={typeFilters}
      titleFilter={titleFilter}
      selectedMonthYear={selectedMonthYear}
      />
      {/* <ol className="divide-y divide-gray-100 text-sm leading-6">
        {filteredEvents.map((event) => (
          <li key={event.id} className="relative flex space-x-6  mb-3">
            <div className="flex-auto bg-white rounded-lg p-5">
              <div className="flex justify-between">
                <div>
                  <h6 className='font-regular text-xs text-sky-700'>{event.event_type}</h6>
                  <h3 className="font-semibold text-sky-900 text-lg">{event.title}</h3>
                </div>
                <div className="text-right">
                  <time dateTime={event.event_date} className="block">
                    {formatDate(event.event_date)} ({formatTime(event.time)})
                  </time>
                  {event.location && (
                    <div className="mt-1 text-sm text-gray-500">{event.location}</div>
                  )}
                </div>
              </div>
            </div>

          </li>
        ))}
      </ol> */}
    </div>
    </div>
  );
}

export default CalendarComponent;
