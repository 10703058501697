import React, { useState, useEffect } from 'react';
import TextInput from '../BaseAssets/TextInput';
import CurrencyInput from '../BaseAssets/CurrencyInput';
import Checkbox from '../BaseAssets/Checkbox';
import axios from 'axios';
import { BACKEND_IP } from '../..'; // Ensure this points to the correct location of your constants

const TemoinsForm = () => {
  const [formData, setFormData] = useState({
    womanFirstName: '',
    womanLastName: '',
    womanBirthDate: '',
    womanAddress: '',
    womanEmail: '',
    womanPhone: '',
    manFirstName: '',
    manLastName: '',
    manBirthDate: '',
    manAddress: '',
    manEmail: '',
    manPhone: '',
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem('token');
        const config = { headers: { Authorization: `Bearer ${token}` } };
        const response = await axios.get(`${BACKEND_IP}/mariage`, config);
        const data = response.data;

        setFormData({
          womanFirstName: data.woman_first_name || '',
          womanLastName: data.woman_last_name || '',
          womanBirthDate: data.woman_birth_date || '',
          womanAddress: data.woman_address || '',
          womanEmail: data.woman_email || '',
          womanPhone: data.woman_phone || '',
          manFirstName: data.man_first_name || '',
          manLastName: data.man_last_name || '',
          manBirthDate: data.man_birth_date || '',
          manAddress: data.man_address || '',
          manEmail: data.man_email || '',
          manPhone: data.man_phone || '',
        });
        setLoading(false);
      } catch (error) {
        console.error('Failed to fetch user data:', error);
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  if (loading) {
    return <div></div>; // Consider using a spinner or a more descriptive message
  }

    return (
          <div className='bg-white p-6 mt-4 rounded-lg shadow'>

      <div className='flex flex-col md:flex-row'>
        <div className='md:w-1/3'>
          
          
          <h4 className='text-lg font-semibold'>Les témoins</h4>
          <p>Le principal concernant notre jour J.</p>


        </div>
        

        <div className='md:w-2/3'>

                </div>
            </div>
            </div>
  );
};

export default TemoinsForm;
