import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BACKEND_IP } from '../..';

const MarriageNames = () => {
  const [names, setNames] = useState({ womanFirstName: '', manFirstName: '' });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchMarriageNames = async () => {
      try {
        const apiUrl = `${BACKEND_IP}/mariage/names`;
        const response = await axios.get(apiUrl, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        });
        const { woman_first_name, man_first_name } = response.data;

        // Delay the state update to ensure skeleton is displayed for minimum time
        setTimeout(() => {
          setNames({ womanFirstName: woman_first_name, manFirstName: man_first_name });
          setIsLoading(false);
        }, 700); // Delay of 1 second
      } catch (err) {
        console.error('Error fetching marriage names:', err);
        setError('Failed to fetch marriage names. Please try again.');
      }
    };

    fetchMarriageNames();
  }, []);

  if (isLoading) return <div className="names-skeleton"></div>; // Skeleton loader
  if (error) return <p>{error}</p>;

  return (
    <div>
      <h2 className='font-bold text-xl text-sky-900 '>Mariage de {names.womanFirstName} & {names.manFirstName}</h2>
    </div>
  );
};

export default MarriageNames;
