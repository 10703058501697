import React, { useState, Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon, AdjustmentsHorizontalIcon } from '@heroicons/react/20/solid';


function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const generateMonthYearOptions = () => {
  const options = [];
  const currentDate = new Date();
  currentDate.setMonth(currentDate.getMonth() - 3);

  for (let i = 0; i < 22; i++) {
    options.push({
      value: `${currentDate.getFullYear()}-${currentDate.getMonth() + 1 < 10 ? `0${currentDate.getMonth() + 1}` : currentDate.getMonth() + 1}`,
      label: `${new Date(currentDate.getFullYear(), currentDate.getMonth()).toLocaleString('default', { month: 'long' })} ${currentDate.getFullYear()}`,
    });
    currentDate.setMonth(currentDate.getMonth() + 1);
  }

  return options;
};

function MonthYearDropdown({ options, selectedMonthYear, setSelectedMonthYear }) {
  // Find the label for the selected month/year or default to "Toutes les dates"
  const selectedLabel = options.find(option => option.value === selectedMonthYear)?.label || 'Toutes les dates';

  return (
    <Menu as="div" className="relative inline-block text-left w-full">
      <div>
        <Menu.Button className="inline-flex justify-between rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 w-full">
          {selectedLabel}
          <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute z-10 mt-2 w-full origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none max-h-60 overflow-y-auto">
          <div className="py-1">
            {/* Always include "Toutes les dates" as the first option */}
            <Menu.Item>
              {({ active }) => (
                <button
                  type="button"
                  className={classNames(
                    'Toutes les dates' === selectedMonthYear ? 'bg-sky-50 text-sky-900' : '',
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'flex justify-between items-center w-full px-4 py-2 text-sm'
                  )}
                  onClick={() => setSelectedMonthYear('Toutes les dates')}
                >
                  Toutes les dates
                  {'Toutes les dates' === selectedMonthYear && (
                    <CheckIcon className="h-5 w-5 text-sky-500" aria-hidden="true" />
                  )}
                </button>
              )}
            </Menu.Item>
            {/* Mapping other month/year options */}
            {options.map((option) => (
              <Menu.Item key={option.value}>
                {({ active }) => (
                  <button
                    type="button"
                    className={classNames(
                      option.value === selectedMonthYear ? 'bg-sky-50 text-sky-800' : '',
                      active ? 'bg-sky-50 text-sky-800' : 'text-gray-700',
                      'flex justify-between items-center w-full px-4 py-2 text-sm'
                    )}
                    onClick={() => setSelectedMonthYear(option.value)}
                  >
                    {option.label}
                    {option.value === selectedMonthYear && (
                      <CheckIcon className="h-5 w-5 text-sky-500" aria-hidden="true" />
                    )}
                  </button>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

function FilterTasks({ selectedMonthYear, setSelectedMonthYear, titleFilter, setTitleFilter, typeFilters, setTypeFilters, hideCompletedTasks, setHideCompletedTasks }) {
  const monthYearOptions = generateMonthYearOptions();

  const handleTypeFilterChange = (e) => {
    const type = e.target.value;
    if (e.target.checked) {
      setTypeFilters([...typeFilters, type]);
    } else {
      setTypeFilters(typeFilters.filter((filter) => filter !== type));
    }
  };

   const handleHideCompletedChange = (e) => {
    setHideCompletedTasks(e.target.checked);
  };


  return (
      
    
    <div className="bg-white shadow-sm rounded-lg p-7 py-6 pb-9">
      <div className="flex flex-wrap items-center justify-between sm:flex-nowrap mb-6">
        <div className="flex items-center">
          <h4 className="text-md font-bold leading-6 text-sky-800 font-dm">Filtres</h4>
        </div>
      </div>
<div className="mt-0 mb-6 text-sm font-medium">
        <label htmlFor="hideCompleted" className="flex items-center">
          <input
            id="hideCompleted"
            name="hideCompleted"
            type="checkbox"
            checked={hideCompletedTasks}
            onChange={handleHideCompletedChange}
            className="h-4 w-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
          />
          <span className="ml-2 font-sm text-sky-800">Cacher les tâches faites</span>
        </label>
      </div>
        <label htmlFor="eventTitle" className="block text-sm font-medium text-sky-900 mb-1">Titre</label>

      <input
        type="text"
        value={titleFilter}
        onChange={(e) => setTitleFilter(e.target.value)}
        placeholder="Titre de la tâche"
        className="block w-full rounded-lg border-gray-300 py-2 text-gray-900 placeholder-gray-400 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm mb-4"
      />

        <label htmlFor="eventTitle" className="block text-sm font-medium text-sky-900 mb-1">Date</label>

      <MonthYearDropdown
        options={monthYearOptions}
        selectedMonthYear={selectedMonthYear}
        setSelectedMonthYear={setSelectedMonthYear}
            />
            
        <label htmlFor="eventTitle" className="block text-sm font-medium text-sky-900 mb-2 mt-4">Type</label>

      <div className="space-y-2">
        {['Organisation', 'Prestataires', 'Invités', 'Rendez-vous'].map((type) => (
          <div key={type} className="flex items-start">
            <div className="flex items-center h-5">
              <input
                id={type}
                name={type}
                type="checkbox"
                value={type}
                checked={typeFilters.includes(type)}
                onChange={handleTypeFilterChange}
                className="h-4 w-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
              />
            </div>
            <div className="ml-3 text-sm">
              <label htmlFor={type} className="font-regular text-sky-800">{type}</label>
            </div>

          </div>
        ))}
        </div>
        

            
    </div>
  );
}

export default FilterTasks;