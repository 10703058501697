import React, { useState } from 'react';
import DeleteConfirmationModal from '../BaseAssets/DeleteConfirmationModal';
import ExpensesStats from './ExpensesStats';
import EditExpenseItemModal from './EditExpenseItemModal';
import { EyeIcon, TrashIcon } from '@heroicons/react/24/solid'; // Import icons


const ExpensesTable = ({ expenses, onDelete, onEditSave }) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
    const totalExpenses = expenses.reduce((acc, expense) => acc + parseFloat(expense.amount || 0), 0);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
const [itemToEdit, setItemToEdit] = useState(null);


  const handleDeleteClick = (item) => {
    setItemToDelete(item);
    setIsDeleteModalOpen(true);
  };

  const handleConfirmDelete = () => {
    onDelete(itemToDelete.id); // Call the onDelete function passed as a prop with the item ID
    setIsDeleteModalOpen(false); // Close the modal
  };

  const handleEditClick = (item) => {
  setItemToEdit(item);
  setIsEditModalOpen(true);
};

    const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Intl.DateTimeFormat('fr-FR', options).format(new Date(dateString));
  };

  // Function to format currency
  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(amount);
  };
  
  return (
    <div className="flex flex-col">

      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
   
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Entreprise
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Date
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Montant
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Payé par
                  </th>
                  <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                    
                  </th>
                </tr>
              </thead>
              <tbody>
                {expenses.map((item, itemIdx) => (
                  <tr key={item.id} className={itemIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-semibold text-sky-900">{item.provider}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-sky-900">{formatDate(item.date)}</td> {/* Format date */}
            <td className="px-6 py-4 whitespace-nowrap text-sm text-sky-900">{formatCurrency(item.amount)}</td> {/* Format amount */}
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-sky-900">{item.paid_by}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-sky-900 flex justify-end items-center">
              <button onClick={() => handleEditClick(item)} className="text-sky-800 hover:text-sky-600 mr-3">
                <EyeIcon className="h-4 w-4" /> {/* Edit icon */}
              </button>
              <button onClick={() => handleDeleteClick(item)} className="text-slate-300 hover:text-red-500">
                <TrashIcon className="h-4 w-4" /> {/* Trash icon */}
              </button>
            </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <DeleteConfirmationModal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onConfirm={handleConfirmDelete}
        itemName={itemToDelete?.title} // Display the title of the item being deleted
      />
      <EditExpenseItemModal
  isOpen={isEditModalOpen}
  onClose={() => setIsEditModalOpen(false)}
  item={itemToEdit}
  onSave={onEditSave} // Use the prop here
/>

    </div>

    
  );
};

export default ExpensesTable;
