import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoneyBillWave } from '@fortawesome/free-solid-svg-icons'; // Assuming you have FontAwesome set up

const ExpensesStats = ({ totalExpenses }) => {
  // Formatting the totalExpenses as Euro currency
  const formattedTotalExpenses = new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: 'EUR',
  }).format(totalExpenses);

  return (
    <div className="bg-white p-4 border rounded-lg flex justify-between items-center">
      <div className="flex items-center">
        <FontAwesomeIcon icon={faMoneyBillWave} className="text-sky-900 mr-3 w-4 h-4" /> {/* Money bill wave icon for expenses */}
        <h4 className="text-md font-semibold text-sky-900">Dépenses</h4>
      </div>
      <div>
        <p className="text-lg font-bold text-sky-800">{formattedTotalExpenses}</p> {/* Display formatted expenses */}
      </div>
    </div>
  );
};

export default ExpensesStats;
