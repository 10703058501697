// AddTableForm.js
import React, { useState } from 'react';
import axios from 'axios';
import { BACKEND_IP } from '../..';
import TextInput from '../BaseAssets/TextInput';
import WPButton from '../BaseAssets/WPButton';


function AddTableForm({ mariageId }) {
  const [tableName, setTableName] = useState('');
  const [numberOfGuests, setNumberOfGuests] = useState(0);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('token');
      await axios.post(`${BACKEND_IP}/api/tables`, { name: tableName, number_of_guests: numberOfGuests, mariage_id: mariageId }, {
        headers: { Authorization: `Bearer ${token}` }
      });
    //   onTableAdded(); // Callback to refresh tables list
    } catch (error) {
      console.error('Error adding table:', error);
    }
  };

  return (
    <form onSubmit={handleSubmit} className='mt-4'>
      <TextInput
        label="Nom"
        name="time"
        type="text"
        value={tableName}
        onChange={(e) => setTableName(e.target.value)}
        placeholder="Nom de la table"
        required />
      
      <TextInput
        label="Nombre de places"
        name="time"
        type="number"
        value={numberOfGuests}
        onChange={(e) => setNumberOfGuests(e.target.value)}
        placeholder="Number of Guests"
        required min="1" />
      
        <WPButton
          type="submit"
          label="Ajouter une table"
            className="inline-flex mt-2 justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
      />
      

    </form>
  );
}

export default AddTableForm;
