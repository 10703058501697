import React, { useState, useEffect } from 'react';
import axios from 'axios';
import EditGuestModal from './EditGuestModal';
import { BACKEND_IP } from '../..';
import { EyeIcon, TrashIcon } from '@heroicons/react/24/solid';


const GuestListTable = ({ guests, fetchGuests }) => {
  const [sortField, setSortField] = useState('last_name'); // Default sort by last name
  const [sortOrder, setSortOrder] = useState('asc'); // Default sort order ascending
  const [selectedGuest, setSelectedGuest] = useState(null);
  const [localGuests, setLocalGuests] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteGuestId, setDeleteGuestId] = useState(null);
  
  const confirmDelete = (id) => {
  setShowDeleteModal(true);
  setDeleteGuestId(id);
};

const deleteConfirmed = async () => {
  setShowDeleteModal(false);
  await handleDelete(deleteGuestId);
  setDeleteGuestId(null);
};

const deleteCancelled = () => {
  setShowDeleteModal(false);
  setDeleteGuestId(null);
};


  const fieldDisplayNames = {
    presence: 'RSVP',
    last_name: 'Nom',
    first_name: 'Prénom',
    side: 'Côté',
    relashionship: 'Relation'

  };

  const columnWidths = {
    first_name: '20%',
    last_name: '20%',
    presence: '10%',
    side: '20%',
    relashionship: '20%',
    actions: '10%'
  };

  const handleDelete = async (id) => {
    try {
      const token = localStorage.getItem('token');
      await axios.delete(`${BACKEND_IP}/api/guests/${id}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      fetchGuests();
    } catch (error) {
      console.error(error.message);
    }
  };

    const handleEdit = (guest) => {
    setSelectedGuest(guest); // Set the selected guest for editing
  };

    useEffect(() => {
  // Convert presence to boolean and sort by last name initially when setting local guests
  const convertedAndSortedGuests = guests.map(guest => ({
    ...guest,
    presence: guest.presence === true || guest.presence === 'true', // Ensure presence is a boolean
  })).sort((a, b) => {
    // Check if the field is a boolean and handle accordingly
    if (typeof a[sortField] === 'boolean') {
      return a[sortField] === b[sortField] ? 0 : a[sortField] ? -1 : 1;
    }

    // Handle string fields (and others that can be safely converted to string)
    const valueA = (a[sortField] || '').toString().toLowerCase();
    const valueB = (b[sortField] || '').toString().toLowerCase();
    if (valueA < valueB) return -1;
    if (valueA > valueB) return 1;
    return 0;
  });
  setLocalGuests(convertedAndSortedGuests);
}, [guests, sortField]);


  const handlePresenceChange = async (changedGuest) => {
    const updatedLocalGuests = localGuests.map(guest => {
      if (guest.id === changedGuest.id) {
        return { ...guest, presence: !guest.presence };
      }
      return guest;
    });
    setLocalGuests(updatedLocalGuests);

    try {
      const token = localStorage.getItem('token');
      await axios.put(`${BACKEND_IP}/api/guests/${changedGuest.id}`, {
        presence: !changedGuest.presence
      }, {
        headers: { Authorization: `Bearer ${token}` },
      });
    } catch (error) {
      console.error("Error updating presence:", error.message);
      fetchGuests(); // Re-fetch guests to ensure UI is in sync with the backend
    }
  };




const handleSort = (field) => {
  if (sortField === field) {
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  } else {
    setSortField(field);
    setSortOrder('asc');
  }

  const sorted = [...localGuests].sort((a, b) => {
    const isAsc = sortOrder === 'asc';

    // Handle boolean fields
    if (typeof a[field] === 'boolean') {
      return a[field] === b[field] ? 0 : a[field] ? (isAsc ? -1 : 1) : (isAsc ? 1 : -1);
    }

    // Handle string fields (and others that can be safely converted to string)
    const valueA = (a[field] || '').toString().toLowerCase();
    const valueB = (b[field] || '').toString().toLowerCase();
    if (valueA < valueB) return isAsc ? -1 : 1;
    if (valueA > valueB) return isAsc ? 1 : -1;
    return 0;
  });

  setLocalGuests(sorted);
};

  const sortedGuests = guests.sort((a, b) => {
    if (!sortField) return 0;
    if (a[sortField] < b[sortField]) return sortOrder === 'asc' ? -1 : 1;
    if (a[sortField] > b[sortField]) return sortOrder === 'asc' ? 1 : -1;
    return 0;
  });

  return (
    <div>
      <div className="overflow-x-auto rounded-md shadow">
        <table className="min-w-full divide-y divide-slate-200 bg-white">
          <thead>
            <tr className='bg-slate-50'>
              {Object.keys(fieldDisplayNames).map((field) => (
                <th
                  key={field}
                  style={{ width: columnWidths[field] }}
                  className="whitespace-nowrap px-4 py-3 text-left text-sm font-semibold text-slate-700 cursor-pointer"
                  onClick={() => handleSort(field)}
                >
                  {fieldDisplayNames[field]} {sortField === field ? (sortOrder === 'asc' ? '↑' : '↓') : ''}
                </th>
              ))}
              <th style={{ width: columnWidths['actions'] }} className="whitespace-nowrap px-4 py-4 text-left text-sm font-semibold text-slate-600"></th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
        {localGuests.map((guest) => (
          <tr key={`${guest.id}-${guest.presence}`}>
            <td className="whitespace-nowrap px-2 pl-6 py-2 text-sm text-slate-500">
                <input
                type="checkbox"
                checked={guest.presence}
                onChange={() => handlePresenceChange(guest)}
                className='rounded-md '
              />
                </td>
                <td className="whitespace-nowrap px-4 py-0 text-sm font-semibold text-sky-900">{guest.last_name}</td>
                <td className="whitespace-nowrap px-2 py-0 text-sm font-medium text-sky-800">{guest.first_name}</td>
                
            <td className="whitespace-nowrap px-2 py-2 text-sm text-slate-400">{guest.side}</td>
            <td className="whitespace-nowrap px-2 py-2 text-sm text-slate-400">{guest.relashionship}</td>
            <td className="flex whitespace-nowrap px-2 py-3 text-sm text-slate-500">
              <span onClick={() => handleEdit(guest)} style={{ marginLeft: '10px', cursor: 'pointer' }}>
  <EyeIcon className="w-4 h-4 text-sky-600" />
</span>
<span onClick={() => confirmDelete(guest.id)} style={{ cursor: 'pointer' }}>
  <TrashIcon className="w-4 h-4 text-slate-300 ml-2" />
</span>

</td>
</tr>
))}
</tbody>
</table>
</div>
{showDeleteModal && (
  <div className="fixed inset-0 bg-gray-600 bg-opacity-75 flex items-center justify-center p-4">
    <div className="bg-white rounded-lg p-4">
      <p>Are you sure you want to delete this guest?</p>
      <div className="flex justify-end space-x-2">
        <button onClick={deleteCancelled} className="bg-gray-500 text-white p-2 rounded">Cancel</button>
        <button onClick={deleteConfirmed} className="bg-red-500 text-white p-2 rounded">Delete</button>
      </div>
    </div>
  </div>
      )}
      
       {selectedGuest && (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      zIndex: 50,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}>
      <EditGuestModal
        guest={selectedGuest}
        onClose={() => setSelectedGuest(null)}
        updateGuest={fetchGuests}
      />
    </div>
  )}
</div>
  );
};

export default GuestListTable;
