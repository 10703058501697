import React, { useState, useEffect, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Layout from "../components/BaseAssets/Layout";
import PageTitle from "../components/BaseAssets/PageTitle";
import { ChevronRightIcon, HomeIcon } from "@heroicons/react/20/solid";
import Conseil from "../components/BaseAssets/Conseil";
import FileModal from "../components/DevisAndFactures/FileModal";
const DevisFactures = () => {
  const [folderName, setFolderName] = useState("");
  const [uploading, setUploading] = useState(false);
  const [creatingFolder, setCreatingFolder] = useState(false);
  const [items, setItems] = useState([]);
  const [fileSrc, setFileSrc] = useState("");
  const [showFileModal, setShowFileModal] = useState(false);
  const [currentFolderId, setCurrentFolderId] = useState(null);
  const [breadcrumbs, setBreadcrumbs] = useState([{ id: null, name: "Home" }]);

  useEffect(() => {
    fetchData(currentFolderId);
  }, [currentFolderId]);
  useEffect(() => {
    fileSrc != "" && setShowFileModal(true);
  }, [fileSrc]);

  const fetchData = async (folderId = null) => {
    const token = localStorage.getItem("token");
    const folderQueries =
      folderId === null ? "?parentId=null" : `?parentId=${folderId}`;
    const fileQueries =
      folderId === null ? "?folderId=null" : `?folderId=${folderId}`;

    try {
      const foldersResponse = await fetch(
        `http://localhost:3001/folders${folderQueries}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      const filesResponse = await fetch(
        `http://localhost:3001/files${fileQueries}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (!foldersResponse.ok || !filesResponse.ok)
        throw new Error("HTTP error!");

      const folders = await foldersResponse.json();
      const files = await filesResponse.json();
      console.log(files);
      // Filter folders to show based on the currentFolderId
      const filteredFolders = folders.filter(
        (folder) => folder.parent_folder_id === currentFolderId
      );
      setItems([
        ...filteredFolders.map((folder) => ({ ...folder, type: "folder" })),
        ...files.map((file) => ({ ...file, type: "file" })),
      ]);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const navigateToFolder = (folderId, folderName) => {
    setCurrentFolderId(folderId);
    const newPath = [...breadcrumbs, { id: folderId, name: folderName }];
    setBreadcrumbs(newPath);
    fetchData(folderId);
  };

  const handleBreadcrumbClick = (folderId) => {
    const breadcrumbIndex = breadcrumbs.findIndex(
      (crumb) => crumb.id === folderId
    );
    const newBreadcrumbs = breadcrumbs.slice(0, breadcrumbIndex + 1);
    setCurrentFolderId(folderId);
    setBreadcrumbs(newBreadcrumbs);
    fetchData(folderId);
  };

  const handleCreateFolder = async () => {
    setCreatingFolder(true);
    const token = localStorage.getItem("token");
    try {
      const response = await fetch("http://localhost:3001/create-folder", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          name: folderName,
          parentFolderId: currentFolderId,
        }), // Send currentFolderId as parentFolderId
      });
      if (!response.ok) throw new Error("HTTP error!");
      setFolderName("");
      fetchData(currentFolderId);
    } catch (error) {
      console.error("Error creating folder:", error.message);
    } finally {
      setCreatingFolder(false);
    }
  };

  const onDrop = useCallback(
    async (acceptedFiles) => {
      setUploading(true);
      const token = localStorage.getItem("token");
      const formData = new FormData();
      acceptedFiles.forEach((file) => {
        formData.append("file", file);
        formData.append("folderId", currentFolderId || "null");
      });

      try {
        const response = await fetch("http://localhost:3001/upload-file", {
          method: "POST",
          headers: { Authorization: `Bearer ${token}` },
          body: formData,
        });
        if (!response.ok) throw new Error("File upload failed");
        fetchData(currentFolderId); // Fetch data for current folder to refresh the list
      } catch (error) {
        console.error("Error uploading file:", error);
      } finally {
        setUploading(false);
      }
    },
    [currentFolderId]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleOnDragEnd = async (result) => {
    const { source, destination } = result;

    // Dropped outside the list or on an undefined destination
    if (!destination) return;

    // Dropped on the same place it was picked up from
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    )
      return;

    const sourceItem = items[source.index];

    // Implement logic based on where the draggable is dropped
    if (sourceItem.type === "folder") {
      // Assuming destination.droppableId is the ID of the folder where the item is dropped
      const destinationFolderId = destination.droppableId.replace(
        "droppable-",
        ""
      );

      // If dropped on a different folder, update the parent
      if (destinationFolderId !== sourceItem.id.toString()) {
        try {
          const token = localStorage.getItem("token");
          const response = await fetch(`http://localhost:3001/move-item`, {
            method: "PATCH",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              itemId: sourceItem.id,
              newParentId: destinationFolderId,
              itemType: "folder",
            }),
          });

          if (!response.ok) throw new Error("Failed to move folder");
          fetchData(currentFolderId); // Refresh the list
        } catch (error) {
          console.error("Error moving folder:", error);
        }
      }
    }
  };

  return (
    <Layout>
      <div className="p-4">
        <PageTitle
          title="Devis & Factures"
          subtitle="Manage your files and folders"
        />

        {/* Breadcrumb Navigation */}

        <div className="flex mt-6">
          <div className="w-1/4 mr-8">
            {/* Folder Creation Input */}
            <div className=" mb-4">
              <div>
                <input
                  type="text"
                  placeholder="New Folder Name"
                  value={folderName}
                  onChange={(e) => setFolderName(e.target.value)}
                  disabled={creatingFolder}
                  className="mb-2 shadow appearance-none border rounded py-2 px-3 mr-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline w-full"
                />
              </div>
              <div>
                <button
                  onClick={handleCreateFolder}
                  disabled={creatingFolder || !folderName}
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                  Create Folder
                </button>
              </div>
            </div>

            {/* File Upload Dropzone */}
            <div
              {...getRootProps()}
              className="dropzone p-4 bg-gray-50 border-2 border-dashed border-gray-300 rounded text-center cursor-pointer hover:bg-gray-100 mb-4"
            >
              <input {...getInputProps()} />
              {isDragActive ? (
                <p>Drop the files here...</p>
              ) : (
                <p>Drag 'n' drop files here, or click to select files</p>
              )}
            </div>
            {uploading && <p>Uploading...</p>}

            <Conseil
              title="Le conseil de Caroline"
              text="Vous pouvez ici noter tous les évènements liés à votre mariage : le jour J, les cérémonies, mais aussi les rendez-vous importants ou à ne pas manquer."
              imageUrl="images/ppconseil.png"
            />
          </div>

          <div className="w-3/4">
            <nav className="flex ml-2 mb-4" aria-label="Breadcrumb">
              <ol role="list" className="flex items-center space-x-4">
                <li>
                  <div>
                    <a
                      href="#"
                      className="text-gray-400 hover:text-gray-500"
                      onClick={() => handleBreadcrumbClick(null)}
                    >
                      <HomeIcon
                        className="h-5 w-5 flex-shrink-0"
                        aria-hidden="true"
                      />
                      <span className="sr-only">Home</span>
                    </a>
                  </div>
                </li>
                {breadcrumbs.map((crumb, index) => (
                  <li key={index}>
                    <div className="flex items-center">
                      <ChevronRightIcon
                        className="h-5 w-5 flex-shrink-0 text-gray-400"
                        aria-hidden="true"
                      />
                      <a
                        href="#"
                        className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                        onClick={(e) => {
                          e.preventDefault();
                          handleBreadcrumbClick(crumb.id);
                        }}
                        aria-current={
                          index === breadcrumbs.length - 1 ? "page" : undefined
                        }
                      >
                        {crumb.name}
                      </a>
                    </div>
                  </li>
                ))}
              </ol>
            </nav>

            {/* Items Display (Folders and Files) */}
            <DragDropContext onDragEnd={handleOnDragEnd}>
              {items.map((item, index) => {
                const isFile = item.name.endsWith(
                  ".png" || ".jpg" || ".jpeg" || ".svg" || ".webp" || ".pdf"
                );
                const isImage = item.name.endsWith(
                  ".png" || ".jpg" || ".jpeg" || ".svg" || ".webp"
                );
                const isPdf = item.name.endsWith(".pdf");
                const isFolder = !isFile && !isPdf
                return (
                  <Droppable
                    key={item.id}
                    droppableId={`droppable-${item.id}`}
                    type="FOLDER"
                  >
                    {(provided) => (
                      <div ref={provided.innerRef} {...provided.droppableProps}>
                        <Draggable
                          draggableId={`draggable-${item.id}`}
                          index={index}
                        >
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              onClick={() => {
                                //file extensions

                                isFolder
                                  ? navigateToFolder(item.id, item.name)
                                  : setFileSrc(item.url);
                              }}
                              className="p-4 m-2 bg-white rounded shadow cursor-pointer hover:bg-gray-100"
                            >
                              {isFolder&& (
                                <div className="text-left">{`📁 ${item.name}`}</div>
                              )}
                              {isImage && (
                                <div className="text-left">{`🖼️ ${item.name}`}</div>
                              )}
                              {isPdf && (
                                <div className="text-left">{`🧷 ${item.name}`}</div>
                              )}
                            </div>
                          )}
                        </Draggable>
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                );
              })}
            </DragDropContext>
          </div>
        </div>
      </div>
      {showFileModal && (
        <FileModal fileSrc={fileSrc} onClose={() => setShowFileModal(false)} />
      )}
    </Layout>
  );
};

export default DevisFactures;
