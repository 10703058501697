import React, { useState, useEffect } from 'react';
import Modal from '../BaseAssets/Modal';
import WPButton from '../BaseAssets/WPButton';

const EditExpenseItemModal = ({ isOpen, onClose, item, onSave }) => {
  const [expenseItem, setExpenseItem] = useState({
    id: '',
    title: '',
    provider: '',
    date: '',
    amount: '',
    paid_by: ''
  });

  useEffect(() => {
    if (isOpen && item) {
      setExpenseItem(item);
    }
  }, [item, isOpen]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setExpenseItem({ ...expenseItem, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    onSave(expenseItem);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Edit Expense">
      <p className=' text-gray-600 mb-4 mt-1'>Edit the selected expense.</p>
      <form onSubmit={handleSubmit} className="space-y-5 mt-4">
        <div className='flex gap-x-4'>
          <div className='w-1/2'>
            <label htmlFor="title" className="block text-sm font-medium text-gray-700">Title</label>
            <input
              type="text"
              name="title"
              value={expenseItem.title}
              onChange={handleChange}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
          <div className='w-1/2'>
            <label htmlFor="provider" className="block text-sm font-medium text-gray-700">Provider</label>
            <input
              type="text"
              name="provider"
              value={expenseItem.provider}
              onChange={handleChange}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
        </div>
        <div className='flex gap-x-4'>
          <div className='w-1/2'>
            <label htmlFor="date" className="block text-sm font-medium text-gray-700">Date</label>
            <input
              type="date"
              name="date"
              value={expenseItem.date}
              onChange={handleChange}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
          <div className='w-1/2'>
            <label htmlFor="amount" className="block text-sm font-medium text-gray-700">Amount</label>
            <input
              type="number"
              name="amount"
              value={expenseItem.amount}
              onChange={handleChange}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
        </div>
        <div>
          <label htmlFor="paid_by" className="block text-sm font-medium text-gray-700">Paid By</label>
          <input
            type="text"
            name="paid_by"
            value={expenseItem.paid_by}
            onChange={handleChange}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>
        <div className="mt-5 sm:mt-6">
          <WPButton
            type="submit"
            label="Save Changes"
            className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
          />
        </div>
      </form>
    </Modal>
  );
};

export default EditExpenseItemModal;
