import React, { useState } from 'react';
import axios from 'axios';
import { BACKEND_IP } from '../..';
import TextInput from '../BaseAssets/TextInput';

const EditGuestModal = ({ guest, onClose, updateGuest }) => {
  const [editedGuest, setEditedGuest] = useState({ ...guest });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedGuest(prev => ({ ...prev, [name]: value }));
  };

   const handleUpdate = async () => {
    try {
      const token = localStorage.getItem('token');
      console.log('Updating guest with data:', editedGuest);

      const response = await axios.put(`${BACKEND_IP}/api/guests/${editedGuest.id}`, editedGuest, {
        headers: { Authorization: `Bearer ${token}` },
      });

      console.log('Guest updated successfully:', response.data);
      alert('Guest updated successfully!');
      updateGuest(response.data); // Update the UI based on the successful response
      onClose(); // Close the modal
    } catch (error) {
      console.error('Error updating guest:', error);
      alert('Error updating guest!');
    }
  };





  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white w-full max-w-4xl p-6 rounded-lg shadow">
        <h3 className="text-xl font-bold mb-4 text-sky-800">Modifier invité(e)</h3>
        <form onSubmit={e => e.preventDefault()}>
          <div className="flex gap-12">
          <div className='flex flex-col w-1/2'>
              <div className='block w-full h-auto'>
                <div className="flex w-full gap-4">
          <div className="w-1/2  ">
                                   <TextInput
              label="Prénom"
              name="first_name"
              value={editedGuest.first_name || ''}
              onChange={handleInputChange}
            />
            </div>
                      <div className="w-1/2  ">

            <TextInput
              label="Nom"
              name="last_name"
              value={editedGuest.last_name || ''}
                onChange={handleInputChange}
                className="w-1/2"
              /></div>
               
              </div>
              </div>
            
          <div className="flex w-full gap-4">
            <div className="w-1/2 ">
          
                  <TextInput
              label="Côté"
              name="side"
              value={editedGuest.side || ''}
              onChange={handleInputChange}
            />
            </div>
            <div className="w-1/2">
              <TextInput
              label="Relation"
              name="relationship"
              value={editedGuest.relationship || ''}
              onChange={handleInputChange}
            />
            </div>
          </div>
          <div className="flex w-full gap-4">
          <div className="w-full">
                         <TextInput
              label="Régime alimentaire"
              name="diet"
              value={editedGuest.diet || ''}
                  onChange={handleInputChange}
                  
                  />     
                </div>
                
                
            
              </div>
                          <div className="w-full ">
              <label htmlFor="comment" className="block text-sky-900 text-sm font-medium mb-2 mt-2">Notes</label>
              <textarea id="comment" name="comment" value={editedGuest.comment || ''} onChange={handleInputChange} className="block w-full rounded-md border-0 py-1.5 text-sky-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" rows="3"></textarea>
            </div>
          </div>
          <div className='flex flex-col w-1/2'>
              <div className='block w-full h-auto'>
                
                <div className="flex w-full gap-4">
                <div className=" w-1/2">
              
                          <TextInput
              label="Email"
              name="email"
              value={editedGuest.email || ''}
              onChange={handleInputChange}
            />
            
          </div>
                <div className="w-1/2">
                            <TextInput
              label="Téléphone"
              name="telephone"
              value={editedGuest.telephone || ''}
              onChange={handleInputChange}
            />
                  </div>
                  </div>
                        <TextInput
              label="Adresse"
              name="adresse"
              value={editedGuest.adresse || ''}
                onChange={handleInputChange}
                className="block w-full"
              />
            </div>
            <div className='flex w-full gap-4'>
              <div className="w-1/2  ">

            <TextInput
              label="Code postal"
              name="code_postal"
              value={editedGuest.code_postal || ''}
                onChange={handleInputChange}
                className="w-1/2"
              />
               
              </div>

                      <div className="w-1/2  ">

            <TextInput
              label="Ville"
              name="ville"
              value={editedGuest.ville || ''}
                onChange={handleInputChange}
                className="w-1/2"
              />
               
              </div>
              </div>
              <div className="w-full ">

            <TextInput
              label="Pays"
              name="pays"
              value={editedGuest.pays || ''}
                onChange={handleInputChange}
                className="w-1/2"
              />
               
              </div>

            </div>
            </div>
          {/* Additional fields as needed */}
          
          <div className="flex justify-end mt-4">
            <button type="button" onClick={onClose} className="bg-gray-300 hover:bg-gray-400 text-gray-800 py-2 px-4 rounded-lg focus:outline-none focus:shadow-outline mr-2">Cancel</button>
            <button type="button" onClick={handleUpdate} className="bg-sky-700 hover:bg-sky-800 text-white py-2 px-4 rounded-lg focus:outline-none focus:shadow-outline">Save Changes</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditGuestModal;