import React, { useState, useEffect } from 'react';
import { PlusIcon } from '@heroicons/react/20/solid';

const HelpCenter = () => {
  

  return (

     <div className='bg-white p-6 mt-4 rounded-lg shadow'>

      <div className='flex'>
        <div className='w-1/3'>
          <h4 className='text-lg font-semibold'>Les utilisateurs</h4>
          <p>Les membres de votre espace MonWP.</p>

        </div>
         <div className='w-2/3'>
        
        </div>
      </div>
      </div>
  );
};

export default HelpCenter;
