import React, { useState } from 'react';
import axios from 'axios';
import { BACKEND_IP } from '../..'; // Ensure this path is correct

const TaskDetailsModal = ({ task, onClose, onSave }) => {
  // Initialize state with the task data passed as props
  const offsetDate = (dateString) => {
    const date = new Date(dateString);
    const userTimezoneOffset = date.getTimezoneOffset() * 60000;
    return new Date(date.getTime() - userTimezoneOffset).toISOString().split('T')[0];
  };
  const [customDescription, setCustomDescription] = useState(task?.custom_description || '');
  const [taskTitle, setTaskTitle] = useState(task?.title || '');  // Changed from task?.task_title
const [date, setDate] = useState(task?.date ? offsetDate(task.date) : '');


const handleSave = async () => {
  try {
    // Structure the data as expected by the backend
    const updatedData = {
      task_title: taskTitle, // Ensure this matches the expected field name
      custom_description: customDescription,
      date,
    };

    const response = await axios.put(`${BACKEND_IP}/todo/${task.id}`, updatedData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });

    if (response.status === 200) {
  console.log('Task updated successfully');
  // Adjust the structure to match your state's expected structure
  onSave({ ...task, title: taskTitle, custom_description: customDescription, date: date });
  onClose();
}
 else {
      console.error('Failed to update task');
    }
  } catch (error) {
    console.error('Error updating task:', error);
  }
};





  return (
    <div className="fixed inset-0 z-50 bg-sky-950 bg-opacity-40 flex items-center justify-center">
      <div className="bg-white p-4 rounded w-1/3 rounded-lg">
        <h2 className='text-lg font-bold mb-4 text-sky-900'>Modifier la tâche</h2>
        <div>
          <label htmlFor="taskTitle" className="block text-sm font-medium text-gray-700">Tâche</label>
          <input
            type="text"
            name="taskTitle"
            id="taskTitle"
            value={taskTitle}
            onChange={(e) => setTaskTitle(e.target.value)}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
          />
        </div>
        <div className="mt-4">
          <label htmlFor="customDescription" className="block text-sm font-medium text-gray-700"> Détails</label>
          <input
            type="text"
            name="customDescription"
            id="customDescription"
            value={customDescription}
            onChange={(e) => setCustomDescription(e.target.value)}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
          />
        </div>
        <div className="mt-4">
          <label htmlFor="date" className="block text-sm font-medium text-gray-700">Date</label>
          <input
            type="date"
            name="date"
            id="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
          />
        </div>
        <div className="mt-4 flex justify-end gap-2">
          <button onClick={onClose} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
            Fermer
          </button>
          <button onClick={handleSave} className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
            Enregistrer
          </button>
        </div>
      </div>
    </div>
  );
};

export default TaskDetailsModal;