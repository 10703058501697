import React, { useState, useEffect } from 'react';
import TextInput from '../BaseAssets/TextInput';
import CurrencyInput from '../BaseAssets/CurrencyInput';
import Checkbox from '../BaseAssets/Checkbox';
import axios from 'axios';
import { BACKEND_IP } from '../..'; // Ensure this points to the correct location of your constants


const MariageForm = () => {
  const [formData, setFormData] = useState({
    womanFirstName: '',
    womanLastName: '',
    weddingDate: '',
    weddingPlace: '',
    civilWedding: false,
    religiousWedding: false,
    cocktail: false,
    dinner: false,
    party: false,
    brunch: false,
    weddingBudget: ''
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchMariageData = async () => {
      try {
        const token = localStorage.getItem('token');
        const config = { headers: { Authorization: `Bearer ${token}` } };
        const response = await axios.get(`${BACKEND_IP}/mariage`, config);
        const data = response.data;

        // Use a function to convert dates to the correct format
        const convertDate = (date) => date ? new Date(date).toISOString().split('T')[0] : '';

        setFormData({
          womanFirstName: data.woman_first_name || '',
          womanLastName: data.woman_last_name || '',
          weddingDate: convertDate(data.wedding_date),
          weddingPlace: data.wedding_place || '',
          civilWedding: Boolean(data.civil_wedding),
          religiousWedding: Boolean(data.religious_wedding),
          cocktail: Boolean(data.cocktail),
          dinner: Boolean(data.dinner),
          party: Boolean(data.party),
          brunch: Boolean(data.brunch),
          weddingBudget: data.wedding_budget || ''
        });
        setLoading(false);
      } catch (error) {
        console.error('Failed to fetch mariage data:', error);
        setLoading(false);
      }
    };

    fetchMariageData();
  }, []);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

    const handleSubmit = async (e) => {
  e.preventDefault();

  const processedFormData = {
    wedding_date: formData.weddingDate,
    wedding_place: formData.weddingPlace,
    civil_wedding: formData.civilWedding ? true : false, // Ensure boolean values
    religious_wedding: formData.religiousWedding ? true : false,
    cocktail: formData.cocktail ? true : false,
    dinner: formData.dinner ? true : false,
    party: formData.party ? true : false,
    brunch: formData.brunch ? true : false,
    wedding_budget: formData.weddingBudget // Make sure this is an integer or a string that can be converted to an integer
  };

  try {
    const token = localStorage.getItem('token');
    const config = { headers: { Authorization: `Bearer ${token}` } };
    // Change the request method to PUT
    await axios.put(`${BACKEND_IP}/mariage`, processedFormData, config);
    console.log("Mariage information updated successfully.");
    // Handle post-success actions here, like showing a success message
  } catch (error) {
    console.error('Error updating mariage data:', error);
    // Handle the error, like showing an error message
  }
};





  if (loading) {
    return <div></div>; // Consider using a spinner or a more descriptive message
  }

  return (
    <div className='bg-white p-6 mt-4 rounded-lg shadow'>

      <div className='flex flex-col md:flex-row '>
        <div className='md:w-1/3 pb-4'>
          
          
          <h4 className='text-lg font-semibold'>Les infos principales</h4>
          <p>Le principal concernant notre jour J.</p>


        </div>
        

        <div className='md:w-2/3'>
    <form onSubmit={handleSubmit} className="space-y-6">
      <TextInput
        name="weddingDate"
        value={formData.weddingDate}
        onChange={handleInputChange}
        placeholder="Date du mariage"
        type="date"
      />
      <TextInput
        name="weddingPlace"
        value={formData.weddingPlace}
        onChange={handleInputChange}
        placeholder="Lieu du mariage"
      />
      <Checkbox
        name="civilWedding"
        checked={formData.civilWedding}
        onChange={handleInputChange}
        label="Cérémonie civile"
      />
      <Checkbox
        name="religiousWedding"
        checked={formData.religiousWedding}
        onChange={handleInputChange}
        label="Cérémonie religieuse"
      />
      <Checkbox
        name="cocktail"
        checked={formData.cocktail}
        onChange={handleInputChange}
        label="Vin d'honneur"
      />
      <Checkbox
        name="dinner"
        checked={formData.dinner}
        onChange={handleInputChange}
        label="Dîner"
      />
      <Checkbox
        name="party"
        checked={formData.party}
        onChange={handleInputChange}
        label="Soirée"
      />
      <Checkbox
        name="brunch"
        checked={formData.brunch}
        onChange={handleInputChange}
        label="Brunch"
      />
      <CurrencyInput
        name="weddingBudget"
        value={formData.weddingBudget}
        onChange={handleInputChange}
        placeholder="Budget estimatif"
      />
      <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
        Enregistrer
      </button>
          </form>
          </div>
      </div>
      </div>
  );
};

export default MariageForm;
