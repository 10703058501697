import React, { useState, useEffect } from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import axios from "axios";
import { BACKEND_IP } from "../.."; // Adjust this accordingly
import ViewEventModal from './ViewEventModal'
import CalendarView from "./CalendarView";
import EventListView from "./EventListView";

const daysOfWeek = ["Lun", "Mar", "Mer", "Jeu", "Ven", "Sam", "Dim"];

const CalendarComponent2 = ({
  typeFilters,
  titleFilter,
  selectedMonthYear,
}) => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [fetchedEvents, setFetchedEvents] = useState([]);
  const [events, setEvents] = useState([]);
  const [viewingEvent, setViewingEvent] = useState({});
  const [viewEvent, setViewEvent] = useState(false);
  const [activeTab, setActiveTab] = useState('calendar'); // New state variable for active tab
    const [selectedEvent, setSelectedEvent] = useState(null); // State to track the selected event for editing
  const [isModalOpen, setIsModalOpen] = useState(false);

    const handleEventSelect = (event) => {
    setSelectedEvent(event); // Update the selected event
    setIsModalOpen(true); // Open the modal
  };

  const handleViewEvent = (event) => {
  setViewingEvent(event);
  setViewEvent(true);
};


  useEffect(() => {
    const fetchEvents = async () => {
      const token = localStorage.getItem("token");
      try {
        const response = await axios.get(`${BACKEND_IP}/calendar_events`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setFetchedEvents(response.data);
        setEvents(response.data);
      } catch (error) {
        console.error("Error fetching events:", error);
      }
    };

    fetchEvents();
  }, [currentDate]);

  useEffect(() => {
    setEvents((state) => {
      if (selectedMonthYear == "" && typeFilters.length == 0) {
        const filtered = fetchedEvents.filter((event) => {
          //lower case for case insensitivity
          return event.title.toLowerCase().includes(titleFilter.toLowerCase());
        });
        return filtered;
      }
      if (selectedMonthYear != "" && typeFilters.length == 0) {
        const filtered = fetchedEvents.filter((event) => {
          const monthAndYear = event.event_date.slice(0, 7);
          return (
            monthAndYear == selectedMonthYear &&
            //lower case for case insensitivity
            event.title.toLowerCase().includes(titleFilter.toLowerCase())
          );
        });
        return filtered;
      }
      if (selectedMonthYear == "" && typeFilters.length != 0) {
        const filtered = fetchedEvents.filter((event) => {
          const monthAndYear = event.event_date.slice(0, 7);
          return (
            typeFilters.includes(event.event_type) &&
            //lower case for case insensitivity
            event.title.toLowerCase().includes(titleFilter.toLowerCase())
          );
        });
        return filtered;
      }
      if (selectedMonthYear != "" && typeFilters.length != 0) {
        const filtered = fetchedEvents.filter((event) => {
          const monthAndYear = event.event_date.slice(0, 7);
          return (
            monthAndYear == selectedMonthYear &&
            typeFilters.includes(event.event_type) &&
            //lower case for case insensitivity
            event.title.toLowerCase().includes(titleFilter.toLowerCase())
          );
        });
        return filtered;
      }
    });
  }, [typeFilters, titleFilter, selectedMonthYear]);

  const onSave = async (updatedEvent) => {
  // Optionally, send update to backend here
  try {
    const response = await axios.put(`${BACKEND_IP}/calendar_event/${updatedEvent.id}`, updatedEvent, {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    });

    if (response.status === 200) {
      // Update the state with the updated event data
      const updatedEvents = events.map(event =>
        event.id === updatedEvent.id ? { ...event, ...updatedEvent } : event
      );
      setEvents(updatedEvents);
      setViewEvent(false); // Close the modal
    } else {
      console.error('Failed to update event');
    }
  } catch (error) {
    console.error('Error updating event:', error);
  }
};


  const handleMonthChange = (num) => {
    setCurrentDate(
      new Date(currentDate.getFullYear(), currentDate.getMonth() + num, 1)
    );
  };

  const getCalendarDays = () => {
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();
    const firstDayOfMonth = new Date(year, month, 1);
    const firstDayOfWeek = firstDayOfMonth.getDay() || 7; // Adjust for weeks starting on Monday

    const startOffset = (firstDayOfWeek - 1) % 7; // Days to subtract to get to the first day on the grid
    const startDay = new Date(firstDayOfMonth);
    startDay.setDate(firstDayOfMonth.getDate() - startOffset);

    const daysArray = [];
    // Generate grid days
    for (let i = 0; i < 42; i++) {
      // Maximum 6 weeks
      daysArray.push(new Date(startDay));
      startDay.setDate(startDay.getDate() + 1);
    }

    // Check if the last row is entirely in the next month and remove it if so
    const lastDay = daysArray[daysArray.length - 1];
    if (lastDay.getMonth() !== month) {
      daysArray.splice(-7);
    }

    return daysArray;
  };

  const mapEventsToDays = (days) => {
    return days.map((day) => {
      const dayEvents = events.filter((event) => {
        const eventDate = new Date(event.event_date);
        return eventDate.toDateString() === day.toDateString();
      });
      return { day, events: dayEvents };
    });
  };
  const formatTime = (timeString) => {
    if (!timeString) return "";
    const hoursAndMinutes = timeString.slice(0, 5);
    const [hours, minutes] = hoursAndMinutes.split(":");
    return `${hours}:${minutes}`;
  };
  const renderCalendarGrid = () => {
  const calendarDays = getCalendarDays();
    const daysWithEvents = mapEventsToDays(calendarDays);
    

    const handleSaveEvent = async (updatedEvent) => {
  try {
    const response = await axios.put(`${BACKEND_IP}/calendar_event/${updatedEvent.id}`, updatedEvent, {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    });
    if (response.status === 200) {
      console.log('Event updated successfully');
      // Update your local state or perform any other actions necessary after saving
    } else {
      console.error('Failed to update event');
    }
  } catch (error) {
    console.error('Error updating event:', error);
  }
    };
    
  

  return (
    <div className="grid border overflow-y-auto grid-cols-7 divide-x divide-y divide-gray-200">
      {daysWithEvents.map(({ day, events }, index) => (
        <div
          key={index}
          className={`aspect-square h-32 overflow-auto p-2 flex flex-col items-left justify-start ${
            day.getMonth() !== currentDate.getMonth()
              ? "bg-gray-100"
              : "bg-white"
          }`}
        >
          <div className="text-xs mb-1 flex justify-between items-center">
            <span className={`${
              day && events.length > 0
                ? "fond-bleu text-white rounded-full w-5 h-5 flex items-center justify-center"
                : "text-gray-400"
            }`}>
              {day.getDate()}
            </span>
          </div>
          <div className="flex flex-col gap-1">
            {day &&
              events.map((event) => (
                <div
                  onClick={() => {
                    setViewEvent(true);
                    setViewingEvent(event);
                  }}
                  key={event.id}
                  className="cursor-pointer text-xs font-medium text-sky-700 hover:text-sky-800 truncate"
                  style={{ minHeight: '20px' }} // Set a minimum height for each event
                >
                  {event.title}
                  <div className="text-slate-400 text-ellipsis overflow-hidden">{formatTime(event.time)}</div>
                </div>
              ))}
          </div>
        </div>
      ))}
{viewEvent && (
  <ViewEventModal
    isOpen={viewEvent} // Assuming viewEvent is a boolean
    event={viewingEvent}
    onClose={() => setViewEvent(false)}
          onSave={onSave}
                  initialData={selectedEvent}

  />
)}
    </div>
  );
  };
  
    const renderContent = () => {
  switch (activeTab) {
    case 'calendar':
      return <CalendarView
        currentDate={currentDate}
        handleMonthChange={handleMonthChange}
        daysOfWeek={daysOfWeek}
        renderCalendarGrid={renderCalendarGrid}
      />;
    case 'list':
      return <EventListView events={events} onViewEvent={handleViewEvent} />;
    default:
      return null;
  }
};


   return (
    <div className="">
      <div className="tabs mb-4">
        <button
          className={`px-4 py-2 rounded-full mr-3 font-semibold text-sm transition duration-100 ease-in-out ${activeTab === 'calendar' ? 'text-sky-800 font-semibold bg-sky-800 bg-opacity-20 tab-beige ' : 'text-gray-500 bg-sky-800 bg-opacity-5'}`}
          onClick={() => setActiveTab('calendar')}
        >
          Calendrier
        </button>
        <button
          className={`px-4 py-2 rounded-full mr-3 font-semibold text-sm transition duration-100 ease-in-out ${activeTab === 'list' ? 'text-sky-800 font-semibold bg-sky-800 bg-opacity-20 tab-beige' : 'text-gray-500 bg-sky-800 bg-opacity-5'}`}
          onClick={() => setActiveTab('list')}
        >
          Liste
        </button>
      </div>
      {renderContent()}
      {viewEvent && (
  <ViewEventModal
    isOpen={viewEvent} // Assuming viewEvent is a boolean
    event={viewingEvent}
    onClose={() => setViewEvent(false)}
           onSave={onSave}
                   initialData={selectedEvent}

  />
      )}
    </div>
  );
};


export default CalendarComponent2;
