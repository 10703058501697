import React, { useState } from 'react';
import WPButton from '../../BaseAssets/WPButton';
import Checkout from './Checkout';

const stepsContent = [
  {
    imgSrc: "/images/welcome-img.png",
    title: "Bienvenue sur MonWeddingPlanner 🎉",
    description: "Nous avons créé ce petit logiciel pour vous aider à gérer toute l'organisation du plus beau jour de votre vie facilement et en un seul endroit. Pour commencer et paramétrer votre compte, remplissez quelques questions concernant votre mariage."
  },
  {
    imgSrc: "/images/signup1.jpg",
    title: "Étape 2: Planification",
    description: "Découvrez comment planifier efficacement votre mariage avec nos outils dédiés. Organisez votre agenda, gérez votre budget, et plus encore."
  },
  {
    imgSrc: "/images/signup1.jpg",
    title: "Étape 3: Inspiration",
    description: "Laissez-vous inspirer par nos idées et conseils pour rendre votre mariage unique. Trouvez votre style, vos thèmes, et personnalisez votre cérémonie à votre image."
  },
  {
    imgSrc: "/images/welcome-img.png",
    title: "Finalisez votre inscription",
    description: "Vous êtes à un pas de terminer l'organisation de votre mariage. Procédez au paiement pour débloquer toutes les fonctionnalités."
  }
];

const CheckoutComponent = () => {
  const [currentStep, setCurrentStep] = useState(0);

  const nextStep = () => {
    setCurrentStep((prevStep) => (prevStep < stepsContent.length - 1 ? prevStep + 1 : prevStep));
  };

  const prevStep = () => {
    setCurrentStep((prevStep) => (prevStep > 0 ? prevStep - 1 : prevStep));
  };

  return (
    <div className='flex' style={{ minHeight: '88vh' }}>
      <div className='w-1/2'>
        <img src={stepsContent[currentStep].imgSrc} alt="Step Image" className="object-cover h-full w-full" />
      </div>
      <div className='w-1/2 flex flex-col justify-between' style={{ maxHeight: '100vh' }}>
        <div className='overflow-auto py-4'>
          {currentStep < stepsContent.length - 1 ? (
            <>
              <h2 className="text-xl font-bold text-sky-800">{stepsContent[currentStep].title}</h2>
              <p className="mt-2 text-gray-600">{stepsContent[currentStep].description}</p>
            </>
          ) : (
            <Checkout />
          )}
        </div>
        <div className="flex justify-between p-4">
          {currentStep > 0 && (
            <WPButton label="Précédent" onClick={prevStep} color="gray" />
          )}
          {currentStep < stepsContent.length - 1 && (
            <WPButton label="Suivant →" onClick={nextStep} color="blue" />
          )}
        </div>
      </div>
    </div>
  );
};

export default CheckoutComponent;
