import React, { useState } from "react";
import EditModal from "./EditModal";
const FileModal = ({
  fileSrc,
  onClose,
}) => {
  const [showEditModal, setShowEditModal] = useState(false);
  const isPdf = fileSrc.endsWith(".pdf");
  const isImage = fileSrc.endsWith(
    ".png" || ".jpg" || ".jpeg" || ".svg" || ".webp"
  );
  return (
    <div className="fixed inset-0 z-50 bg-gray-600 bg-opacity-75 flex items-center justify-center">
      <div className="bg-white p-4 rounded">
        <img
          /* onClick={()=>window.location.href=fileSrc} 
          className="cursor-pointer" */
          src={fileSrc}
        />

        <button
          onClick={onClose}
          className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Close
        </button>
        <button
          onClick={() => setShowEditModal(true)}
          className="mt-4 mx-2 bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
        >
          Edit
        </button>
        {isPdf && (
          <button
            onClick={() => (window.location.href = fileSrc)}
            className="mt-4 ml-3 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
          >
            View Pdf
          </button>
        )}
      </div>
      {showEditModal && (
        <EditModal
          fileSrc={fileSrc}
          onClose={()=>setShowEditModal(false)}
        />
      )}
    </div>
  );
};

export default FileModal;
