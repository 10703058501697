import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { BACKEND_IP } from '..'; // Ensure this points to your actual backend IP or domain

const useFetchMarriageNames = () => {
  const [marriageNames, setMarriageNames] = useState([]);

  const fetchMarriageNames = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      if (token) {
        const response = await axios.get(`${BACKEND_IP}/mariage/names`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        // Assuming the response contains an object with woman_first_name and man_first_name
        if (response.data && response.data.woman_first_name && response.data.man_first_name) {
          setMarriageNames([response.data.woman_first_name, response.data.man_first_name]);
        }
      }
    } catch (error) {
      console.error('Error fetching marriage names:', error);
      // Handle the error as needed, e.g., set an error state, show a notification, etc.
    }
  }, []);

  useEffect(() => {
    fetchMarriageNames();
  }, [fetchMarriageNames]);

  return marriageNames; // Return only marriageNames since the fetch function is encapsulated
};

export default useFetchMarriageNames;
