import React, { useEffect, useState } from 'react';
import Modal from '../BaseAssets/Modal';
import TextInput from '../BaseAssets/TextInput';
import Dropdown from '../BaseAssets/Dropdown';
import { PlusIcon } from '@heroicons/react/20/solid';
import WPButton from '../BaseAssets/WPButton';

const AddGuestModal = ({ isOpen, onClose, newGuests, handleInputChange, handleSubmit, addGuest, marriageSides }) => {



    const handleSideChange = (index, selectedSide) => {
    // Create a copy of the newGuests array
    const updatedGuests = [...newGuests];
    // Update the 'side' property of the guest at the specified index
    updatedGuests[index] = { ...updatedGuests[index], side: selectedSide };
    // Update the newGuests state with the modified array
    handleInputChange(index, { target: { name: 'side', value: selectedSide } });
    };
    

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Ajouter un(e) invité(e)" icon={PlusIcon} className=" text-white font-bold py-2 px-4 rounded w-full mb-0">
      <p className=' text-gray-600 mb-4 mt-1'>Vous pourrez ensuite ajouter des détails individuels à chaque invité.</p>
      <form onSubmit={handleSubmit} className="flex flex-col gap-0">
        {newGuests.map((guest, index) => (
          <div key={index} className="flex flex-row gap-3 mb-0">
            {/* Use TextInput component for first name */}
            <div className='w-4/12'>
                <TextInput
              // label="First Name"
              name="firstName"
              value={guest.firstName}
              onChange={(e) => handleInputChange(index, e)}
                placeholder="Prénom"
                className="mb-0"
                    />
                </div>
                            <div className='w-4/12'>

            {/* Use TextInput component for last name */}
            <TextInput
              // label="Last Name"
              name="lastName"
              value={guest.lastName}
              onChange={(e) => handleInputChange(index, e)}
                placeholder="Nom"
                className="mb-0"
                    />
                    </div>
            <div className='w-4/12 mt-2'>
                    {/* <label className="block text-sm mb-2 font-medium leading-6 text-sky-900">
      hjkg
      </label> */}
              <Dropdown
                selected={guest.side}
                setSelected={(value) => handleSideChange(index, value)}
                options={marriageSides.map(side => ({ value: side, label: side }))}
                placeholder="Côté"
                className="mb-0"
              />
            </div>
          </div>
        ))}
        <div className="flex gap-x-4 mt-4">
          <WPButton
            type="button"
            label="+"
            color="green"
            onClick={addGuest}
            className="rounded-3xl text-xl"
          />
          <WPButton
            type="submit"
            label="Ajouter à ma liste"
            
            className=""
          />
        </div>
      </form>
    </Modal>
  );
};

export default AddGuestModal;
