import React, { useState, useEffect } from "react";
import { loadStripe } from '@stripe/stripe-js';
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from '@stripe/react-stripe-js';
import { BACKEND_IP } from "../../..";

// This is your test public API key.
const stripePromise = loadStripe("pk_test_51OoRlmFvtZTbqC6rW0WphovVbwYG57Aa7SzF5LZU62uJR4etYNrqkEbthCrAapLNOFhDebYvI0skkmHpm8w1n8jv00Z9eUtgQn");

const Checkout = () => {
  const [clientSecret, setClientSecret] = useState('');

  useEffect(() => {
    const token = localStorage.getItem('token'); // Get the stored token

    fetch(`${BACKEND_IP}/create-checkout-session`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
      },
    })
    .then(res => {
      if (!res.ok) throw new Error('Network response was not ok');
      return res.json();
    })
    .then(data => {
      setClientSecret(data.clientSecret);
    })
    .catch(error => console.error('Error:', error));
  }, []);

  return (
    <div id="checkout">
      {clientSecret && (
        <EmbeddedCheckoutProvider stripe={stripePromise} options={{ clientSecret }}>
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      )}
    </div>
  );
};

export default Checkout;
