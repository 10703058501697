import React from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';

const CalendarView = ({ currentDate, handleMonthChange, daysOfWeek, renderCalendarGrid }) => {
  const month = currentDate.toLocaleString("default", { month: "long" });
    const capitalizedMonth = month.charAt(0).toUpperCase() + month.slice(1);
  
    return (
      <div className=" bg-white shadow-sm ring-1 ring-gray-900/5 rounded-xl">
      <div className="flex p-4 px-6 bg-gray-50 items-center justify-between mb-4 rounded-t-xl">
        <h2 className="text-md font-semibold text-sky-700">
          {capitalizedMonth}{" "}
          {currentDate.getFullYear()}
        </h2>
        <div className="flex">
          <ChevronLeftIcon
            className="h-6 w-6 text-gray-400 cursor-pointer"
            onClick={() => handleMonthChange(-1)}
          />
          <ChevronRightIcon
            className="h-6 w-6 text-gray-400 cursor-pointer ml-2"
            onClick={() => handleMonthChange(1)}
          />
                  </div>
          </div>
          <div className="px-6 py-0 pb-6 ">
      <div className="grid grid-cols-7 text-xs font-medium text-center text-gray-500 ">
        {daysOfWeek.map((day) => (
          <div key={day} className="px-1 py-2">
            {day}
          </div>
        ))}
              </div>
              
      {renderCalendarGrid()}
          </div>
          </div>
  );
};

export default CalendarView;
