import React, { useState } from 'react';

function Tabs({ tabs }) {
  const [selectedTab, setSelectedTab] = useState(tabs[0].name);

  return (
    <div>
      <nav className="flex overflow-x-auto py-0">
        <ul className="flex gap-x-6 px-4 text-sm font-semibold leading bg-white p-4 w-full rounded-lg">
          {tabs.map((tab) => (
            <li key={tab.name} className="cursor-pointer">
              <a
                onClick={() => setSelectedTab(tab.name)}
                className={tab.name === selectedTab ? 'text-sky-600' : 'hover:text-slate-800'}
              >
                {tab.name}
              </a>
            </li>
          ))}
        </ul>
      </nav>
      <div className='bg-white mt-4 rounded-lg h-96'>
        {tabs.map((tab) => {
          if (tab.name === selectedTab) {
            return (
              <div key={tab.name}>
                {tab.content}
              </div>
            );
          }
          return null;
        })}
      </div>
    </div>
  );
}

export default Tabs;
