// GuestToTableForm.js
import React, { Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function GuestToTableForm({
  guests,
  tables,
  selectedGuests,
  setSelectedGuests,
  selectedTable,
  setSelectedTable,
  handleSubmit
})


{
  return (
    <form onSubmit={handleSubmit}>
      {/* Guests Dropdown for multiple selection */}
      <div className="w-full max-w-xs mx-auto">
        <Listbox value={selectedGuests} onChange={setSelectedGuests} multiple>
          {({ open }) => (
            <>
              <Listbox.Label className="block text-sm font-medium text-gray-700">Les invités</Listbox.Label>
              <div className="mt-1 relative">
                <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
                  <span className="block truncate">
                    {selectedGuests.length > 0
                      ? selectedGuests.map(guest => guest.first_name + ' ' + guest.last_name).join(', ')
                      : 'Sélection...'}
                  </span>
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </span>
                </Listbox.Button>
                <Transition
                  show={open}
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base ring-1 ring-black ring-opacity-5 shadow-lg focus:outline-none sm:text-sm">
                    {guests.map((guest) => (
                      <Listbox.Option
                        key={guest.id}
                        className={({ active }) =>
                          classNames(active ? 'bg-indigo-600 text-white' : 'text-gray-900', 'cursor-default select-none relative py-2 pl-10 pr-4')
                        }
                        value={guest}
                      >
                        {({ selected, active }) => (
                          <>
                            <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                              {guest.first_name} {guest.last_name}
                            </span>
                            {selected ? (
                              <span
                                className={classNames(
                                  active ? 'text-white' : 'text-indigo-600',
                                  'absolute inset-y-0 left-0 flex items-center pl-3'
                                )}
                              >
                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </>
          )}
        </Listbox>
      </div>

      {/* Tables Dropdown for single selection */}
      <div className="w-full max-w-xs mx-auto mt-4">
        <Listbox value={selectedTable} onChange={setSelectedTable}>
          {({ open }) => (
            <>
              <Listbox.Label className="block text-sm font-medium text-gray-700">Les tables</Listbox.Label>
              <div className="mt-1 relative">
                <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
                  <span className="block truncate">{selectedTable ? tables.find(table => table.id === selectedTable).name : 'Sélection...'}</span>
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </span>
                </Listbox.Button>
                <Transition
                  show={open}
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base ring-1 ring-black ring-opacity-5 shadow-lg focus:outline-none sm:text-sm">
                    {tables.map((table) => (
                      <Listbox.Option
                        key={table.id}
                        className={({ active }) =>
                          classNames(active ? 'bg-indigo-600 text-white' : 'text-gray-900', 'cursor-default select-none relative py-2 pl-10 pr-4')
                        }
                        value={table.id}
                      >
                        {({ selected, active }) => (
                          <>
                            <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                              {table.name}
                            </span>
                            {selected ? (
                              <span
                                className={classNames(
                                  active ? 'text-white' : 'text-indigo-600',
                                  'absolute inset-y-0 left-0 flex items-center pl-3'
                                )}
                              >
                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </>
          )}
        </Listbox>
      </div>

      <button type="submit" className="mt-4 px-4 py-2 text-sm justify-start  font-semibold fond-bleu hover:bg-sky-700 w-full text-white rounded-md shadow-sm">Ajouter à la table</button>
    </form>
  );
}
