import React, { useState, useEffect } from 'react';
import axios from 'axios';
import MarriageNames from './MariageNames';
import { BACKEND_IP } from '../..'; // Ensure this path is correct
import { PencilIcon } from '@heroicons/react/24/outline'; // Ensure you have this icon
import Checkout from '../Onboarding/Checkout/Checkout';
import { useNavigate } from 'react-router-dom'; // Import useNavigate


export default function MariageRecap() {
  const [photo1, setPhoto1] = useState('/images/placeholder.png');
  const [photo2, setPhoto2] = useState('/images/placeholder.png');
  const [loading1, setLoading1] = useState(true); // Loading state for photo1
  const [loading2, setLoading2] = useState(true); // Loading state for photo2
  const navigate = useNavigate();
  const [welcomeTextLoading, setWelcomeTextLoading] = useState(true);
const [cardContentLoading, setCardContentLoading] = useState(true);

    const MIN_DISPLAY_TIME = 700; // Minimum display time for skeleton in milliseconds

  useEffect(() => {
    const fetchPhotos = async () => {
      try {
        const response = await axios.get(`${BACKEND_IP}/api/mariage/photos`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });

        // Delay the state update to ensure skeleton is displayed for minimum time
        setTimeout(() => {
          setPhoto1(response.data.photo1 || '/images/placeholder.png');
          setLoading1(false); // Update loading state after minimum display time
        }, MIN_DISPLAY_TIME);

        setTimeout(() => {
          setPhoto2(response.data.photo2 || '/images/placeholder.png');
          setLoading2(false); // Update loading state after minimum display time
        }, MIN_DISPLAY_TIME);
      } catch (error) {
        console.error('Error fetching marriage photos:', error);
      }
    };

    fetchPhotos();
  }, []);

  useEffect(() => {
  // Simulating fetch operation with setTimeout
  // Replace this with your actual data fetching logic
  const timer = setTimeout(() => {
    setWelcomeTextLoading(false);
    setCardContentLoading(false);
  }, 700); // Ensures skeleton loaders display for at least 1 second

  return () => clearTimeout(timer); // Cleanup the timer
}, []);


  const handleCardClick = (path) => {
    // Navigate to the path
    navigate(path);
  };

  useEffect(() => {
    const fetchPhotos = async () => {
      try {
        const response = await axios.get(`${BACKEND_IP}/api/mariage/photos`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`, // Ensure you have the auth token
          },
        });
        // Assuming the backend returns an object with photo1 and photo2 URLs
        setPhoto1(response.data.photo1 || '/images/placeholder.png');
        setPhoto2(response.data.photo2 || '/images/placeholder.png');
      } catch (error) {
        console.error('Error fetching marriage photos:', error);
      }
    };

    fetchPhotos();
  }, []); // Empty dependency array means this effect runs once on mount


  const uploadImage = async (photoType) => {
    // Trigger file input
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = '.png, .jpg, .jpeg';
    input.onchange = async (e) => {
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append('photo', file);
      formData.append('photoType', photoType);

      try {
        const response = await axios.post(`${BACKEND_IP}/upload-mariage-photo`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${localStorage.getItem('token')}`, // Ensure you have the auth token
          },
        });

        if (photoType === 'photo_1') {
          setPhoto1(response.data.url);
        } else if (photoType === 'photo_2') {
          setPhoto2(response.data.url);
        }
      } catch (error) {
        console.error('Error uploading image:', error);
      }
    };
    input.click();
  };

  return (
    <div className="bg-white shadow sm:rounded-lg">
      <div className="px-4 py-5 sm:p-7 ">
        
       <div className="flex justify-between items-center  max-w-full -mt-3">
  <MarriageNames />

  <div className="text-lg text-sky-900 justify-end">
  {cardContentLoading ? (
    <div className="countdown-skeleton"></div>
  ) : (
    <p className="remain-days">🤍 Plus que 67 jours avant le Jour J !</p>
  )}
</div>
        </div>
<div className='-mt-1 text-sky-900'>
  {welcomeTextLoading ? (
    <div className="text-skeleton"></div>
  ) : (
    'Bienvenue dans votre Wedding Planner'
  )}
</div>

        <div className="flex justify-between space-x-4 my-6 w-full">
        {[
          { photo: photo1, loading: loading1 },
          { photo: photo2, loading: loading2 }
        ].map((item, index) => (
          <div key={index} className="flex-1 relative group">
            {item.loading ? (
              <div className="skeleton w-full h-56 rounded-lg"></div> // Skeleton loader
            ) : (
              <img
                src={item.photo}
                alt={`Marriage Photo ${index + 1}`}
                className="w-full h-56 object-cover rounded-lg"
                onLoad={() => { index === 0 ? setLoading1(false) : setLoading2(false); }} // Update loading state on image load
              />
            )}
            <div
              className="absolute top-2 left-2 p-1 bg-white rounded-full cursor-pointer opacity-0 group-hover:opacity-70"
              onClick={() => uploadImage(`photo_${index + 1}`)}
            >
              <PencilIcon className="h-4 w-4 text-sky-600 " />
            </div>
          </div>
        ))}
      </div>
        

        
                  {/* <h3 className='font-semibold text-md mt-6 text-sky-900'>Actions rapides</h3> */}

        <div className="flex justify-between space-x-4 mt-2 w-full">
  <div className="flex-1 relative group" onClick={() => handleCardClick('/informations#mariage')}>
    <div className="bg-white shadow rounded-lg p-4 cursor-pointer hover:bg-gray-100 h-28">
      {cardContentLoading ? (
        <>
          <div className="icon-skeleton"></div>
          <div className="card-text-skeleton"></div>
        </>
      ) : (
        <>
          <img src="/images/heart-icon.png" alt="Heart Icon" className="inline-block h-5 w-5 mr-2 mb-4" />
          <p className="font-semibold text-md text-sky-900 hover:text-sky-800">
            Les détails du mariage
          </p>
        </>
      )}
    </div>
  </div>

  {/* Repeat the pattern for other cards */}
  {['/invites', '/prestataires', '/budget'].map((path, index) => (
    <div key={index} className="flex-1 relative group" onClick={() => handleCardClick(path)}>
      <div className="bg-white shadow rounded-lg p-4 cursor-pointer hover:bg-gray-100 h-28">
        {cardContentLoading ? (
          <>
            <div className="icon-skeleton"></div>
            <div className="card-text-skeleton"></div>
          </>
        ) : (
          <>
            <img src={index === 0 ? "/images/add-icon.png" : index === 1 ? "/images/add-icon.png" : "/images/add-icon.png"} alt="Icon" className="inline-block h-5 w-5 mr-2 mb-4" />
            <p className="font-semibold text-md text-sky-900 hover:text-sky-800">
              {index === 0 ? "Ajouter un invité" : index === 1 ? "Ajouter un prestataire" : "Ajouter une dépense"}
            </p>
          </>
        )}
      </div>
    </div>
  ))}
</div>


        
      </div>

      
      
    </div>
    
    
  );
}