import React from "react";

const EditModal = ({ onClose }) => {
  return (
    <div className="fixed inset-0 z-[51] bg-gray-600 bg-opacity-75 flex items-center justify-center">
      <div className="bg-white p-4 rounded">
        <div>Edit</div>
        <button
          onClick={onClose}
          className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default EditModal;
