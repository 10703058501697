import React, { useState, useEffect } from 'react';
import axios from 'axios';
import GuestToTableForm from './GuestToTableForm'; // Import the form component
import { BACKEND_IP } from '../..';

function AssignGuestToTableForm({ mariageId, onGuestAssigned }) {
  const [guests, setGuests] = useState([]);
  const [tables, setTables] = useState([]);
  const [selectedGuest, setSelectedGuest] = useState('');
  const [selectedTable, setSelectedTable] = useState('');
  const [selectedGuests, setSelectedGuests] = useState([]);

  useEffect(() => {
    const fetchGuestsAndTables = async () => {
      try {
        const token = localStorage.getItem('token');
        const guestsResponse = await axios.get(`${BACKEND_IP}/api/guests`, { headers: { Authorization: `Bearer ${token}` } });
        const tablesResponse = await axios.get(`${BACKEND_IP}/api/tables`, { headers: { Authorization: `Bearer ${token}` }, params: { mariage_id: mariageId } });
        setGuests(guestsResponse.data);
        setTables(tablesResponse.data);
      } catch (error) {
        console.error('Error fetching guests and tables:', error);
      }
    };

    fetchGuestsAndTables();
  }, [mariageId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('token');
      const guestIds = selectedGuests.map(guest => guest.id);
      for (const guestId of guestIds) {
        await axios.patch(`${BACKEND_IP}/api/guests/assign`, { guest_id: guestId, table_id: selectedTable }, {
          headers: { Authorization: `Bearer ${token}` }
        });
      }
      if (typeof onGuestAssigned === 'function') {
        onGuestAssigned(); // Callback to refresh guests list
      } else {
        console.error('Error: onGuestAssigned is not a function');
      }
      // Delay the reload by 500 milliseconds (adjust as needed)
      setTimeout(() => {
        window.location.reload(); // Refresh the page
      }, 500);
    } catch (error) {
      console.error('Error assigning guests to table:', error);
    }
  };

  return (
    <div>
      <h2 className='font-semibold mb-4'>Ajouter des invités</h2>
      <GuestToTableForm
        guests={guests}
        tables={tables}
        selectedGuests={selectedGuests}
        setSelectedGuests={setSelectedGuests}
        selectedTable={selectedTable}
        setSelectedTable={setSelectedTable}
        handleSubmit={handleSubmit}
      />
    </div>
  );
}

export default AssignGuestToTableForm;
