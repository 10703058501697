import React from 'react';

const CurrencyInput = ({ label, name, value, onChange, placeholder, className }) => {
  // Function to format the input value
  const formatInputValue = (inputValue) => {
    // Convert to string and remove any non-numeric characters, except decimal point
    const stringValue = inputValue.toString().replace(/[^0-9.]/g, '');
    // Format with commas and limit to 2 decimal places, if necessary
    const formattedValue = new Intl.NumberFormat('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(parseFloat(stringValue));
    return formattedValue;
  };

  // Handler to process changes and formatting
  const handleChange = (event) => {
    const { value } = event.target;
    const formattedValue = formatInputValue(value);
    // Call the onChange prop function with a simulated event object containing the formatted value
    onChange({
      target: {
        name: name,
        value: formattedValue,
      }
    });
  };

  return (
    <div className={className}> {/* Use the className prop here */}
      <label htmlFor={name} className="block text-sm font-medium leading-6 text-gray-900">
        {label}
      </label>
      <div className="relative mt-2 rounded-md shadow-sm">
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <span className="text-gray-500 sm:text-sm">€</span>
        </div>
        <input
          type="text"
          name={name}
          id={name}
          value={formatInputValue(value)}
          onChange={handleChange}
          className="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          placeholder={placeholder || "0.00"}
          aria-describedby={`${name}-currency`}
        />
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
          <span className="text-gray-500 sm:text-sm" id={`${name}-currency`}>
            EUR
          </span>
        </div>
      </div>
    </div>
  );
};

export default CurrencyInput;
