import React, { useState, useEffect } from 'react';
import Modal from '../BaseAssets/Modal';
import WPButton from '../BaseAssets/WPButton';

const EditBudgetItemModal = ({ isOpen, onClose, item, updateBudgetItem }) => {
  const [budgetItem, setBudgetItem] = useState({
    id: '',
    name: '',
    price: '',
    type: '',
    provider: '',
    comment: ''
  });

  useEffect(() => {
    if (isOpen && item) {
      setBudgetItem(item);
    }
  }, [item, isOpen]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setBudgetItem({ ...budgetItem, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (updateBudgetItem) {
      await updateBudgetItem(budgetItem);
      onClose();
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Modifier l'élément du budget">
      <form onSubmit={handleSubmit} className="space-y-6 mt-6">
        {/* Name Field */}
        <div>
          <label htmlFor="name" className="block text-sm font-medium text-gray-700">Nom</label>
          <input
            type="text"
            name="name"
            value={budgetItem.name}
            onChange={handleChange}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
          />
        </div>

        {/* Price Field */}
        <div>
          <label htmlFor="price" className="block text-sm font-medium text-gray-700">Prix</label>
          <input
            type="number"
            name="price"
            value={budgetItem.price}
            onChange={handleChange}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
          />
        </div>

        {/* Type Field */}
        <div>
          <label htmlFor="type" className="block text-sm font-medium text-gray-700">Type</label>
          <input
            type="text"
            name="type"
            value={budgetItem.type}
            onChange={handleChange}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
          />
        </div>

        {/* Provider Field */}
        <div>
          <label htmlFor="provider" className="block text-sm font-medium text-gray-700">Fournisseur</label>
          <input
            type="text"
            name="provider"
            value={budgetItem.provider}
            onChange={handleChange}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
          />
        </div>

        {/* Comment Field */}
        <div>
          <label htmlFor="comment" className="block text-sm font-medium text-gray-700">Commentaire</label>
          <textarea
            name="comment"
            value={budgetItem.comment}
            onChange={handleChange}
            rows="3"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
          ></textarea>
        </div>

        <WPButton
          type="submit"
          label="Sauvegarder les modifications"
          className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
        />
      </form>
    </Modal>
  );
};

export default EditBudgetItemModal;
