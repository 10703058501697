import React, { useState, useEffect, Fragment } from 'react';
import axios from 'axios';
import { BACKEND_IP } from '../..'; // Ensure this path is correct
import { Menu, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon, EyeIcon, TrashIcon } from '@heroicons/react/20/solid';
import AddTask from './AddTask';
import FilterTasks from './FilterTasks';
import Conseil from '../BaseAssets/Conseil';
import TaskDetailsModal from './TaskDetailsModal';
import DeleteConfirmationModal from '../BaseAssets/DeleteConfirmationModal';
import TasksByMonth from './TasksByMonth';  
import TasksByType from './TasksByType';
function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}




function Todo() {
  const [todos, setTodos] = useState([]);
  const [selectedTab, setSelectedTab] = useState('month'); // 'month' or 'type'
  const [selectedTodos, setSelectedTodos] = useState([]);
  const [selectedMonthYear, setSelectedMonthYear] = useState('Toutes les dates');
  const [loading, setLoading] = useState(true);
  const [dateFilter, setDateFilter] = useState({ startDate: '', endDate: '' });
  const [titleFilter, setTitleFilter] = useState('');
  const [typeFilters, setTypeFilters] = useState([]);
      const [isModalOpen, setIsModalOpen] = useState(false);

  const [currentTask, setCurrentTask] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [taskToDelete, setTaskToDelete] = useState(null);
  const [hideCompletedTasks, setHideCompletedTasks] = useState(false); // New state for hiding completed tasks





  useEffect(() => {
    const fetchTodos = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${BACKEND_IP}/todo`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        });
        setTodos(response.data);
      } catch (error) {
        console.error('Error fetching todos:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchTodos();
  }, []);

  useEffect(() => {
    const fetchTodos = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${BACKEND_IP}/todo`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        setTodos(response.data);
      } catch (error) {
        console.error('Error fetching todos:', error);
      }
      setLoading(false);
    };

    fetchTodos();
  }, []);

  const handleView = (task) => {
    console.log('View clicked', task);

  setCurrentTask(task);
  setIsModalOpen(true);
};

const handleSaveTask = (updatedTask) => {
  setTodos((prevTodos) =>
    prevTodos.map((todo) => {
      if (todo.id === updatedTask.id) {
        // Adjust this to match the structure of your tasks in the state
        return { ...todo, ...updatedTask };
      }
      return todo;
    })
  );
};






  const closeModal = () => {
    setIsModalOpen(false);
    setCurrentTask(null);
  };

  const handleDeleteClick = (task) => {
  setTaskToDelete(task);
  setIsDeleteModalOpen(true);
};



  
  const toggleAll = () => {
    const areAllSelected = selectedTodos.length === todos.length;
    setSelectedTodos(areAllSelected ? [] : [...todos]);
  };

  const isSelected = (todo) => selectedTodos.some((selectedTodo) => selectedTodo.id === todo.id);

  const toggleTodoSelection = (todo) => {
    if (isSelected(todo)) {
      setSelectedTodos(selectedTodos.filter((selectedTodo) => selectedTodo.id !== todo.id));
    } else {
      setSelectedTodos([...selectedTodos, todo]);
    }
  };

  const handleComplete = async (id, isCompleted) => {
  console.log("Completing task ID:", id); // Log the task primary key ID

  if (!id) {
    console.error('Error: Task ID is undefined or null');
    return;
  }

  try {
    // Example API call to update the task on the backend
    const response = await axios.put(`${BACKEND_IP}/todo/${id}`, {
      is_completed: !isCompleted,
    }, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`, // Adjust as needed for your auth token
      },
    });

    // Check the response to ensure it was successful
    if (response.status === 200) {
      // Update the state with the new task status
      setTodos(todos.map(task => task.id === id ? { ...task, is_completed: !isCompleted } : task));
    } else {
      console.error('Failed to update task completion status');
    }
  } catch (error) {
    console.error('Error updating task:', error);
  }
};

  const handleDelete = async (id) => {
    console.log("Deleting task ID:", id); // Log the task primary key ID

    if (!id) {
      console.error('Error: Task ID is undefined or null');
      return;
    }

    try {
      await axios.delete(`${BACKEND_IP}/todo/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      setTodos(todos.filter((todo) => todo.id !== id));
    } catch (error) {
      console.error('Error deleting task:', error);
    }
  };

  const groupTasksByType = (tasks) => {
    return tasks.reduce((acc, task) => {
      const { type } = task;
      if (!acc[type]) {
        acc[type] = [];
      }
      acc[type].push(task);
      return acc;
    }, {});
  };

  const filterAndGroupTasks = () => {
    let filtered = [...todos];

    if (titleFilter) {
      filtered = filtered.filter(task => task.title.toLowerCase().includes(titleFilter.toLowerCase()));
    }

    if (typeFilters.length > 0) {
      filtered = filtered.filter(task => typeFilters.includes(task.type));
    }

    if (selectedMonthYear !== 'Toutes les dates') {
      const [year, month] = selectedMonthYear.split('-').map(Number);
      filtered = filtered.filter(task => {
        const taskDate = new Date(task.date);
        return taskDate.getFullYear() === year && taskDate.getMonth() + 1 === month;
      });
    }

    if (hideCompletedTasks) {
      filtered = filtered.filter(task => !task.is_completed);
    }

    return groupTasksByType(filtered);
  };

  const filteredAndGroupedTasks = filterAndGroupTasks();


    const fetchTodos = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${BACKEND_IP}/todo`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      setTodos(response.data);
    } catch (error) {
      console.error('Error fetching todos:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTodos(); // Fetch todos when the component mounts
  }, []);

  const handleTaskAdded = async (newTask) => {
    // Assuming newTask is the task object returned by the backend after adding the task
    await fetchTodos(); // Re-fetch todos to include the newly added task
  };
  
  todos.sort((a, b) => {
  // Assuming 'type' is your section and it has a predefined order somewhere
  const sectionOrder = ['Organisation', 'Prestataires', 'Invités', 'Rendez-vous']; // Example section order
  return sectionOrder.indexOf(a.type) - sectionOrder.indexOf(b.type) || new Date(a.date) - new Date(b.date);
});


  // Custom Dropdown for Task Type Selection
 
const handleConfirmDelete = async () => {
  if (!taskToDelete) return;

  try {
    const response = await axios.delete(`${BACKEND_IP}/todo/${taskToDelete.id}`, {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    });

    if (response.status === 200) {
      fetchTodos(); // Re-fetch todos to refresh the list
      setIsDeleteModalOpen(false);
      setTaskToDelete(null);
    }
  } catch (error) {
    console.error('Error deleting task:', error);
  }
};





  const handleTypeFilterChange = (e) => {
  const type = e.target.value;
  if (e.target.checked) {
    // Add the type to the filters if checked
    setTypeFilters([...typeFilters, type]);
  } else {
    // Remove the type from the filters if unchecked
    setTypeFilters(typeFilters.filter((filter) => filter !== type));
  }
  };
  

    const visibleTodos = hideCompletedTasks ? todos.filter(task => !task.is_completed) : todos;


  const tasksGroupedByType = groupTasksByType(todos);




// Add this inside your component return, at an appropriate place





return (
  <div className="flex flex-col md:flex-row overflow-x-hidden">
    <div className="md:w-1/4 pr-6">
      <AddTask onTaskAdded={handleTaskAdded} />
     <FilterTasks
          selectedMonthYear={selectedMonthYear}
          setSelectedMonthYear={setSelectedMonthYear}
          titleFilter={titleFilter}
          setTitleFilter={setTitleFilter}
          typeFilters={typeFilters}
          setTypeFilters={setTypeFilters}
          hideCompletedTasks={hideCompletedTasks}
          setHideCompletedTasks={setHideCompletedTasks} // Pass the state and its setter to FilterTasks
        />
      <Conseil
        title="Le conseil de Caroline"
        text="Vous pouvez ici noter tous les évènements liés à votre mariage : le jour J, les cérémonies, mais aussi les rendez-vous importants ou à ne pas manquer."
        imageUrl="images/ppconseil.png"
      />
    </div>

    <div className="md:w-3/4">
      <div className="flex mb-4">
        <button
          className={`px-4 py-2 rounded-full mr-3 font-semibold text-sm transition duration-100 ease-in-out ${selectedTab === 'month' ? 'text-sky-800 font-semibold  bg-opacity-20 tab-beige' : 'text-gray-500 bg-sky-800 bg-opacity-5'}`}
          aria-selected={selectedTab === 'month'}
          onClick={() => setSelectedTab('month')}
        >
          Par mois
        </button>
        <button
          className={`px-4 py-2 rounded-full font-semibold text-sm transition duration-100 ease-in-out ${selectedTab === 'type' ? 'text-sky-800 font-semibold bg-sky-800 bg-opacity-20 tab-beige ' : 'text-gray-500 bg-sky-800 bg-opacity-5'}`}
          aria-selected={selectedTab === 'type'}
          onClick={() => setSelectedTab('type')}
        >
          Par catégorie
        </button>
      </div>

      {loading ? (
          <p>Loading...</p>
        ) : todos.length === 0 ? (
          <p>No tasks yet!</p>
        ) : (
          <div>
   {selectedTab === 'type' && Object.entries(filteredAndGroupedTasks).map(([type, tasks]) => (
  <TasksByType
    key={type}
    todos={tasks} // tasks here is an array from the filteredAndGroupedTasks object
    handleView={handleView}
    handleComplete={handleComplete}
    handleDeleteClick={handleDeleteClick}
  />
   ))}
              
              {selectedTab === 'month' && (
  <TasksByMonth
    todos={filteredAndGroupedTasks} // Pass filtered and grouped tasks
    handleView={handleView}
    handleComplete={handleComplete}
    handleDeleteClick={handleDeleteClick}
  />
)}

          {/* )}
          {selectedTab === 'type' && (
            <TasksByType
              todos={visibleTodos} // Pass the filtered todos
              handleView={handleView}
              handleComplete={handleComplete}
              handleDeleteClick={handleDeleteClick}
            />
          )} */}
        </div>
      )}
    </div>
    {isModalOpen && (
      <TaskDetailsModal
        task={currentTask}
        onClose={() => setIsModalOpen(false)}
        onSave={handleSaveTask}
      />
    )}

    {isDeleteModalOpen && (
      <DeleteConfirmationModal
        isOpen={isDeleteModalOpen}
        task={taskToDelete}
        onClose={() => setIsDeleteModalOpen(false)}
        onConfirm={handleConfirmDelete}
      />
    )}
  </div>
);
}

export default Todo;

