import React from 'react';
import Tabs from '../BaseAssets/Tabs';
import MariageForm from './mariageForm';
import Utilisateurs from '../Settings/Utilisateurs';
import MariesForm from './mariesForm';
import TemoinsForm from './temoinsForm';

const tabConfig = [
  { name: 'Le mariage', content: <MariageForm /> },
  { name: 'Les mariés', content: <MariesForm /> },
  { name: 'Les témoins', content: <div><TemoinsForm /></div> },
];

function Informations() {
  return (
    <main className="p-4 -mt-8">
      <header className="">
        {/* Reusable Tabs Component */}
        <Tabs tabs={tabConfig} />
      </header>

      {/* Additional structure can be added here if needed */}
    </main>
  );
}

export default Informations;
