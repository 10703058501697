import React from 'react';

const Checkbox = ({ id, name, checked, onChange, label, description, className }) => {
  return (
    <div className={`relative flex mt-2 mb-2 items-start ${className}`}>
      <div className="flex items-center h-6">
        <label htmlFor={id} className="flex items-center cursor-pointer">
          <input
            id={id}
            name={name}
            type="checkbox"
            checked={checked}
            onChange={onChange}
            className="h-4 w-4 rounded border-gray-300 text-sky-600 focus:ring-sky-600 mr-2 focus:ring-1 "
          />
          <span className="text-gray-900">{label}</span>
        </label>
      </div>
      {description && (
        <div className="ml-6 text-sm leading-6">
          <span id={`${id}-description`} className="text-gray-500">
            {description}
          </span>
        </div>
      )}
    </div>
  );
};

export default Checkbox;
