import React, { useState } from 'react';
import Modal from '../BaseAssets/Modal';
import WPButton from '../BaseAssets/WPButton';


const BudgetFormModal = ({ isOpen, onClose, addBudgetItem }) => {
  const [budgetItem, setBudgetItem] = useState({
    name: '',
    price: '',
    type: '',
    provider: '',
    comment: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setBudgetItem({ ...budgetItem, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await addBudgetItem(budgetItem);
    setBudgetItem({ name: '', price: '', type: '', provider: '', comment: '' }); // Reset form fields
    onClose(); // Close the modal
  };

  const formContent = (
    <form onSubmit={handleSubmit} className="space-y-6 mt-6">
      <div className='flex gap-x-4'>
      <div className='w-1/2'>
        <label htmlFor="name" className="block text-sm font-medium text-gray-700">Nom</label>
        <input
          type="text"
            name="name"
            placeholder="Titre de l'élément"

          value={budgetItem.name}
          onChange={handleChange}
          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        />
      </div>
      
              <div className='w-1/2'>
        <label htmlFor="price" className="block text-sm font-medium text-gray-700">Budget</label>
        <input
          type="number"
            name="price"
            placeholder='Le prix estimé en €'
          value={budgetItem.price}
          onChange={handleChange}
          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        />
        </div>
      
        </div>
      <div className='flex gap-x-4'>

        <div className='w-1/2'>
        <label htmlFor="type" className="block text-sm font-medium text-gray-700">Type</label>
        <input
          type="text"
          name="type"
          id="type"
          value={budgetItem.type}
          onChange={handleChange}
          required
          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        />
        </div>
      <div className='w-1/2'> 
        <label htmlFor="provider" className="block text-sm font-medium text-gray-700">Fournisseur</label>
        <input
          type="text"
          name="provider"
          id="provider"
           placeholder='Si déjà choisi...'
          value={budgetItem.provider}
          onChange={handleChange}
          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        />
        </div>
        </div>
      <div>
        <label htmlFor="comment" className="block text-sm font-medium text-gray-700">Notes</label>
        <textarea
          name="comment"
          id="comment"
          placeholder='Une note ou un rappel utile...'
          value={budgetItem.comment}
          onChange={handleChange}
          rows="2"
          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        ></textarea>
      </div>
      <div className="mt-5 sm:mt-6">
        <WPButton
          type="submit"
          label="Ajouter au budget"
          className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
       />
     
      </div>
    </form>
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Ajouter au budget">
            <p className=' text-gray-600 mb-4 mt-1'>Faites vos prévisions pour ne rien oublier.</p>

      {formContent}
    </Modal>
  );
};

export default BudgetFormModal;
