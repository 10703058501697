// ProtectedRoute.js
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from './useAuth'; // Adapt the path as needed
import useChatBubble from './components/BaseAssets/useChatBubble'; // Adapt the path as needed

const ProtectedRoute = () => {
  const { authenticated } = useAuth();
  useChatBubble(); // This will load the chat bubble when the user is authenticated

  return authenticated ? <Outlet /> : <Navigate to="/login" />;
};

export default ProtectedRoute;
