// Home.js
import React from 'react';
import Layout from '../components/BaseAssets/Layout';
import PageTitle from '../components/BaseAssets/PageTitle';
import CalendarComponent from '../components/Calendar/CalendarComponent';

function Calendrier() {

  return (
    <Layout>
      <div className="p-4">
        <PageTitle 
          title="Le calendrier" 
          subtitle="Toutes les actualités de mon mariage ♥" 
        />
              <CalendarComponent />

      </div>
    </Layout>
  );
}

export default Calendrier;
