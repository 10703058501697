// Home.js
import React from 'react';
import Layout from '../components/BaseAssets/Layout';
import PageTitle from '../components/BaseAssets/PageTitle';
import SettingsComponent from '../components/Settings/SettingsComponent';

function Parametres() {

  return (
    <Layout>
      <div className="p-4">
        <PageTitle 
          title="Paramètres" 
          subtitle="Toutes les actualités de mon mariage ♥" 
              />
              <SettingsComponent />

      </div>
    </Layout>
  );
}

export default Parametres;
