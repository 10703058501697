import React from 'react';
import { EyeIcon } from '@heroicons/react/20/solid'; // Importing the Eye icon for view details

const formatDate = (dateString) => {
  const date = new Date(dateString);
  return new Intl.DateTimeFormat('fr-FR', { day: 'numeric', month: 'long' }).format(date);
};

const UpcomingTasksList = ({ tasks, onViewDetails }) => {
  const uncompletedTasks = tasks.filter(task => !task.is_completed).sort((a, b) => new Date(a.date) - new Date(b.date));
  const upcomingTasks = uncompletedTasks.slice(0, 5);

  return (
    <div className='mt-4 p-6 pb-4 rounded-lg bg-white shadow'>
      <div className='flex justify-between'>
        <h3 className="text-lg leading-6 font-bold text-sky-900">Notre todo</h3>
        <a href="/todo" className="text-slate-500 font-medium hover:text-sky-900 text-sm inline-block">Toutes les tâches</a>
      </div>
      <p className="text-md leading-6 text-slate-500">Les prochaines tâches à faire.</p>

      {upcomingTasks.length > 0 ? (
        <div style={{ maxWidth: '100%', overflowX: 'auto' }}> {/* Wrapper to ensure table does not exceed page width */}
          <table className="min-w-full  mt-5">
            <tbody className="bg-white">
              {upcomingTasks.map((task) => (
                <tr key={task.id}>
                  <td className=" py-2 whitespace-nowrap  font-medium text-sky-900" style={{ maxWidth: '270px' }}> {/* Maximum width */}
                    <div onClick={() => onViewDetails(task)} className="truncate cursor-pointer hover:underline" title={task.title}>{task.title}</div> {/* Truncates text */}
                  </td>
                  <td className=" py-2 whitespace-nowrap text-sm text-gray-500 text-right ">{formatDate(task.date)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p>No upcoming tasks</p>
      )}
    </div>
  );
};

export default UpcomingTasksList;
