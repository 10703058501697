import React, { useState, useEffect } from 'react';
import { PlusIcon } from '@heroicons/react/20/solid';

const Utilisateurs = () => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await fetch('http://localhost:3001/api/users', {
        headers: { 'Authorization': `Bearer ${token}` },
      });
      if (!response.ok) throw new Error('HTTP error!');

      const fetchedUsers = await response.json();
      setUsers(fetchedUsers);
    } catch (error) {
      console.error('Error fetching users:', error.message);
    }
  };

  return (

     <div className='bg-white p-6 mt-4 rounded-lg shadow'>

      <div className='flex'>
        <div className='w-1/3'>
          <h4 className='text-lg font-semibold'>Les utilisateurs</h4>
          <p>Les membres de votre espace MonWP.</p>

        </div>
         <div className='w-2/3'>
        <div className='mt-4'>
          {users.map((user, index) => (
            <div key={index} className="p-4 m-2 bg-white rounded shadow cursor-pointer hover:bg-gray-100">
              <p className="text-left text-sm font-medium text-gray-900">{`${user.first_name} ${user.last_name}`}</p>
              <p className="text-left text-sm text-gray-500">{user.email}</p>
              <button
                type="button"
                className="mt-2 inline-flex items-center gap-x-1.5 text-sm font-semibold leading-6 text-gray-900"
              >
                <PlusIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                Invite
              </button>
            </div>
            
          ))}
            </div>
        </div>
      </div>
      </div>
  );
};

export default Utilisateurs;
